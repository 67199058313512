import React, { useState, Fragment } from "react";
import { TouchableOpacity } from "react-native";
import { Animate, Flex, Group, Button, Text, Icon } from "unikit";
import { useNavigation } from "@react-navigation/native";

import { useQuery, GET_TYPE, GET_ENTRIES } from "../api/user";
import { ListViewQuery, Search } from "../components";

export default ({
  label,
  value = [],
  filter = {},
  relation,
  search = false,
  isInput = false,
  onChange,
  type,
  projectId,
  onEdit,
  onRemove,
  onSelect,
  onCopy,
  ...rest
}) => {
  const { push, setOptions, goBack } = useNavigation();
  const [searchData, setSearchData] = useState(null);
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_TYPE, {
    variables: {
      _id: relation,
    },
  });
  const { contenttype } = data;

  const getTitle = () => {
    var title = "";
    if (contenttype) {
      Object.keys(contenttype.schema).map((key, index) => {
        if (contenttype.schema[key].preview === true) {
          title = key;
        }
      });
    }
    return title;
  };
  const titleField = getTitle();

  if (loading || !titleField) return <Flex />;

  const onSelectItem = ({ item, remove, back = false }) => {
    if (back) goBack();
    var newArray = [...value];
    if (remove) {
      var i = newArray.indexOf(item._id);
      newArray.splice(i, 1);
      onChange(newArray);
    } else {
      if (type === "Object") {
        onChange([item._id]);
      } else {
        if (newArray.indexOf(item._id) === -1) {
          newArray.push(item._id);
          onChange(newArray);
        } else {
          theme.alert({ type: "warning", message: "Bereits hinzugefügt" });
        }
      }
    }
  };

  return (
    <Fragment>
      {search ? (
        <Search
          query={GET_ENTRIES}
          searchKey={`content.${titleField}`}
          onChange={({ data }) =>
            setSearchData(
              data["contententrys"] ? data["contententrys"].results || [] : null
            )
          }
          projectId={projectId}
          contentTypeId={relation}
        />
      ) : null}
      <ListViewQuery
        query={GET_ENTRIES}
        searchData={searchData}
        filter={{
          ...(isInput ? { _id: { $in: value } } : {}),
          ...filter,
          contentTypeId: relation,
        }}
        loadMore={isInput}
        label={label}
        sort={{ [`content.${titleField}`]: 1 }}
        gap={0}
        count={contenttype.count || 0}
        name="contententrys"
        scrollEnabled={!isInput}
        infinite={false}
        renderItem={({ item, index, limit }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                push("Entry", {
                  title: item.content[titleField],
                  typeId: relation,
                  _id: item._id,
                  type: "ContentEntry",
                  mode: "update",
                  defaultDoc: { _id: item._id, ...item.content },
                  onSuccess: () => {
                    goBack();
                  },
                  refetchQueries: [
                    {
                      query: GET_ENTRIES,
                      variables: {
                        filter: {
                          ...(isInput ? { _id: { $in: value } } : {}),
                          ...filter,
                          contentTypeId: relation,
                        },
                      },
                    },
                  ],
                });
              }}
            >
              <Animate
                as={Flex}
                delay={index * 100}
                w="100%"
                px={20}
                py={18}
                bg="surface"
                alignItems="center"
                justifyContent="space-between"
                row
                shadow={5}
                borderRadius={5}
              >
                <Flex f={1} style={{ maxWidth: "calc(100% - 200px)" }}>
                  <Text level={5} numberOfLines={1}>
                    {item.content[titleField]}
                  </Text>
                  <Text
                    mt={3}
                    o={0.6}
                    numberOfLines={1}
                    fontSize="label"
                    color="#999"
                  >
                    {item.content[titleField]}
                  </Text>
                </Flex>
                <Flex row>
                  {onSelect ? (
                    <Button
                      onPress={() => {
                        onSelect({ item, back: true });
                      }}
                      size={35}
                      mr={6}
                      light
                    >
                      <Icon name="plus" color="primary" size={20} />
                    </Button>
                  ) : null}
                  {onCopy ? (
                    <Button onPress={onCopy} size={35} mr={6} light>
                      <Icon name="copy" color="primary" size={20} />
                    </Button>
                  ) : null}
                  {onRemove ? (
                    <Button
                      onPress={onRemove}
                      bg="error"
                      size={35}
                      mr={6}
                      light
                    >
                      <Icon name="trash" color="error" size={20} />
                    </Button>
                  ) : null}
                </Flex>
              </Animate>
            </TouchableOpacity>
          );
        }}
        {...rest}
      />
      {isInput ? (
        <Group w="100%" mb={10}>
          <Button
            onPress={() => {
              push("Entries", {
                title: `${label} suchen`,
                typeId: relation,
                value,
                onSelect: onSelectItem,
              });
            }}
            renderLeft={
              <Icon
                name="search"
                color="primary"
                size={20}
                style={{ marginRight: 8 }}
              />
            }
            bg="primary"
          >
            {`${label} suchen`}
          </Button>
          <Button
            onPress={() => {
              push("Entry", {
                projectId: contenttype.projectId,
                title: `${contenttype.name} erstellen`,
                typeId: relation,
                type: "ContentEntry",
                mode: "insert",
                onSuccess: ({ item }) => {
                  onSelectItem({ item, back: true });
                },
              });
            }}
            renderLeft={
              <Icon
                name="plus"
                color="primary"
                size={20}
                style={{ marginRight: 8 }}
              />
            }
            bg="primary"
          >
            {`${label} erstellen`}
          </Button>
        </Group>
      ) : null}
    </Fragment>
  );
};

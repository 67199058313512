import React from "react";
import { Flex, Icon, Touchable, Avatar, Tooltip, Text } from "unikit";

import { langs } from "./LangSelectInput";

const LangSel = ({ value = "de", languages = [], onChange }) => {
  return (
    <Tooltip
      color="rgba(255,255,255,0.8)"
      shadow={50}
      width={350}
      withOverlay={false}
      borderWidth={1}
      borderColor="primary:setAlpha:0.2"
      popover={
        <Flex>
          {Object.values(langs)
            .filter((l) => l.key !== value && languages.indexOf(l.key) > -1)
            .map((item) => (
              <Touchable
                onPress={() => onChange(item.key)}
                alignItems="center"
                row
                p={10}
              >
                <Avatar mr={10} size={30} source={item.image} />
                <Text>{item.label}</Text>
              </Touchable>
            ))}
        </Flex>
      }
    >
      <Flex mr={10} alignItems="center" row>
        <Avatar
          size={30}
          source={langs[value] ? langs[value].image : langs.de.image}
        />
        <Icon name="chevron-down" size={16} ml={6} />
      </Flex>
    </Tooltip>
  );
};

export default LangSel;

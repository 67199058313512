import * as React from "react";
import { Icon, Flex, Touchable, Text } from "unikit";

const StringComponents = [
  {
    type: "Text",
    icon: "x",
  },
  {
    type: "Slug",
    icon: "x",
  },
  {
    type: "Textarea",
    icon: "x",
  },
  {
    type: "RichText",
    icon: "x",
  },
  {
    type: "Select",
    icon: "x",
  },
  {
    type: "Date",
    icon: "x",
  },
];

const BooleanComponents = [
  {
    type: "Toggle",
    icon: "x",
  },
];

const NumberComponents = [
  {
    type: "Number",
    icon: "x",
  },
];

const ArrayComponents = [
  {
    type: "Relation",
    icon: "x",
  },
  {
    type: "Media",
    icon: "x",
  },
  {
    type: "List",
    icon: "x",
  },
  {
    type: "Tags",
    icon: "x",
  },
  {
    type: "Checkboxes",
    icon: "x",
  },
];

const ObjectComponents = [
  {
    type: "Relation",
    icon: "x",
  },
  {
    type: "Media",
    icon: "x",
  },
  {
    type: "Json",
    icon: "x",
  },
];

const getItem = (option, onChange, index, value, name) => {
  return (
    <Touchable
      bg={value === option.type ? "primary" : "surface"}
      p={30}
      w={200}
      onPress={() => {
        onChange(option.type);
        // if (
        //   option.type === "Relation" ||
        //   option.type === "Media" ||
        //   option.type === "List"
        // ) {
        //   onChange("defaultValue", []);
        // }
        // if (option.type === "Number") {
        //   onChange("defaultValue", 0);
        // }
        // if (option.type === "Boolean") {
        //   onChange("defaultValue", false);
        // }
      }}
      borderRadius={5}
      shadow={5}
      mr={10}
      flexCenter
    >
      <Icon
        color={value === option.type ? "#FFF" : "primary"}
        name={option.icon}
      />
      <Text color={value === option.type ? "#FFF" : "primary"}>
        {option.type}
      </Text>
    </Touchable>
  );
};

function Components({ style, name, label, onChange, value, doc }) {
  return (
    <Flex w="100%" pb={10} row wrap>
      {doc?.type === "String"
        ? StringComponents.map((option, index) => {
            return getItem(option, onChange, index, value, name);
          })
        : null}
      {doc?.type === "Boolean"
        ? BooleanComponents.map((option, index) => {
            return getItem(option, onChange, index, value, name);
          })
        : null}
      {doc?.type === "Number"
        ? NumberComponents.map((option, index) => {
            return getItem(option, onChange, index, value, name);
          })
        : null}
      {doc?.type === "Array"
        ? ArrayComponents.map((option, index) => {
            return getItem(option, onChange, index, value, name);
          })
        : null}
      {doc?.type === "Object"
        ? ObjectComponents.map((option, index) => {
            return getItem(option, onChange, index, value, name);
          })
        : null}
    </Flex>
  );
}

export default Components;

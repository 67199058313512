import * as React from "react";

import Reanimated, {
  useAnimatedStyle,
  interpolateColor,
} from "react-native-reanimated";
import { withThemeProps, Pressable, transformColor } from "../../style";

import Flex from "../Flex";
import Animate from "../Animate";
import { isNumber } from "../../util";
import { useUpdateEffect } from "../../hooks";

const AnimatedTrack = Reanimated.createAnimatedComponent(Flex);

interface Props {
  theme: object;
  value: boolean;
  onChange: void;
  size?: number;
  roundness?: number;
  gap?: number;
  trackColor?: string;
  activeTrackColor?: string;
  handleShow?: number;
  renderHandleContent: React.ReactNode;
  disabled?: boolean;
  [key: string]: any;
}

const Track = ({
  trackColor,
  activeTrackColor,
  translationX,
  size,
  theme,
  ...rest
}) => {
  const c1 = transformColor({
    value: trackColor,
    theme,
    themeKey: "colors",
  });
  const c2 = transformColor({
    value: activeTrackColor,
    theme,
    themeKey: "colors",
  });

  const colorStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        translationX.value,
        [0, size],
        [c1, c2]
      ),
    };
  }, []);

  return <AnimatedTrack style={colorStyle} absoluteFill {...rest} />;
};

const Switch = ({
  theme,
  value,
  onChange,
  size = 35,
  roundness,
  gap = 5,
  trackColor = "primary:setAlpha:0.1",
  circleColor = "#FFF",
  activeTrackColor = "primary",
  handleShow,
  renderHandleContent,
  disabled,
  ...rest
}: Props) => {
  const TRACK_WIDTH = size * 2 - gap;
  const circleSize = size - gap * 2;

  const changeValue = (v: boolean) => {
    if (onChange) onChange(v);
  };

  useUpdateEffect(() => {
    if (theme.onFeedback) theme.onFeedback("selection");
  }, [value]);

  return (
    <Pressable
      position="relative"
      activeOpacity={0.8}
      height={size}
      borderRadius={isNumber(roundness) ? roundness : size}
      onPress={() => {
        changeValue(!value);
      }}
      webStyle={{
        cursor: "pointer",
      }}
      {...rest}
      w={TRACK_WIDTH}
    >
      <Animate
        borderRadius={circleSize}
        p={gap}
        to={{
          backgroundColor: transformColor({
            value: value === true ? activeTrackColor : trackColor,
            theme,
            themeKey: "colors",
          }),
        }}
        height="100%"
      >
        <Animate to={{ x: value === true ? circleSize + gap : 1 }}>
          <Flex
            shadow={handleShow || theme.globals.shadow}
            width={circleSize}
            height={circleSize}
            borderRadius={isNumber(roundness) ? roundness - gap / 2 : size}
            borderWidth={1}
            borderColor={`text:setAlpha:0.05`}
            bg={circleColor}
          >
            {renderHandleContent ? renderHandleContent({ value }) : null}
          </Flex>
        </Animate>
      </Animate>
    </Pressable>
  );
};

export default withThemeProps(Switch, "Switch");

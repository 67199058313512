import * as React from "react";
import { Avatar, useTheme, Icon, Text, Touchable } from "unikit";
import dayjs from "dayjs";
import FileItem from "./FileItem";
import ListViewQuery from "./ListViewQuery";

import { GET_FILES } from "../api/user";
import { useNavigation } from "../AppContext";

export default React.memo(
  ({ value = [], onChange, type }) => {
    if (typeof value === "string" && value.length > 0) value = [value];
    const navigation = useNavigation();
    const theme = useTheme();
    const onSelect = ({ item, remove, back = false }) => {
      if (back) navigation.goBack();
      var newArray = [...(value || [])];
      if (remove) {
        var i = newArray.indexOf(item._id);
        newArray.splice(i, 1);
        onChange(newArray);
      } else {
        if (type === "Object") {
          console.log("onSelect", { item });
          onChange([item._id]);
        } else {
          if (newArray.indexOf(item._id) === -1) {
            newArray.push(item._id);
            onChange(newArray);
          } else {
            theme.alert({ type: "warning", message: "Bereits hinzugefügt" });
          }
        }
      }
    };

    return (
      <ListViewQuery
        query={GET_FILES}
        filter={{ _id: { $in: value || [] } }}
        sort={{ createdAt: 1 }}
        gap={10}
        name="files"
        infinite={false}
        scrollEnabled={false}
        renderItem={({ item, index, limit }) => {
          //console.log({ item });
          return (
            <FileItem
              title={item.name}
              desc={`yolo ${dayjs(item.createdAt).format("DD.MM.YYYY")}`}
              image={{ uri: item?.thumbnailUrl || item?.data?.secure_url }}
              onRemove={() => {
                onSelect({ item, remove: true });
              }}
              file={item}
            />
          );
        }}
        lastItem={
          <Touchable
            onPress={() => {
              navigation.push("Media", {
                onSelect: ({ item }) => onSelect({ item, back: true }),
              });
            }}
            flex={1}
            minHeight={200}
            borderColor="primary:setAlpha:0.3"
            bg="primary:setAlpha:0.1"
            borderRadius={10}
            borderWidth={2}
            borderStyle={"dashed"}
            flexCenter
          >
            <Avatar size={50} shadow={5} bg="primary">
              <Icon
                name={
                  type === "Object" && value && value.length > 0
                    ? "refreshCCW"
                    : "plus"
                }
                color="#FFF"
                size={24}
              />
            </Avatar>

            <Text mt={12} color="primary">
              {type === "Object" && value && value.length > 0
                ? "Datei wechseln"
                : "Datei hinzufügen"}
            </Text>
          </Touchable>
        }
        grid
      />
    );
  },
  (prevProps, nextProps) => {
    if (JSON.stringify(prevProps.value) !== JSON.stringify(nextProps.value)) {
      return false;
    } else {
      return true;
    }
  }
);

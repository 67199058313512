import * as React from "react";
import { useTheme } from "unikit";
import dayjs from "dayjs";
import { FileItem, Page, ListViewQuery, FileInput } from "../components";

import { useAppState } from "../AppContext";
import { useQuery, GET_FILES } from "../api/user";
import { FileSchema } from "../contist-server/lib/schemas";

export default function Screen({ navigation, route }) {
  const theme = useTheme();
  const [key, setKey] = React.useState(1);
  const { set, project, callMeteor } = useAppState();

  const deleteFile = async (fileId) => {
    const alertKey = theme.alert({
      type: "surface",
      loading: true,
      timeout: false,
      message: "",
    });

    const result = await callMeteor({
      variables: {
        name: "deleteFile",
        options: {
          projectId: project._id,
          fileId,
        },
      },
    });
    theme.alert({
      key: alertKey,
      loading: false,
      type: "success",
      message: "Datei gelöscht",
      timeout: 2000,
    });
  };

  return (
    <Page
      title="Media"
      goBack={route?.params?.onSelect ? navigation.goBack : undefined}
    >
      <FileInput
        onSuccess={() => {
          setKey((k) => k + 1);
        }}
      />
      <ListViewQuery
        key={`${key}`}
        query={GET_FILES}
        filter={{ projectId: project?._id }}
        sort={{ createdAt: -1 }}
        gap={10}
        name="files"
        infinite={false}
        renderItem={({ item, index, limit }) => {
          return (
            <FileItem
              title={`${item.name}`}
              desc={`${item._id} Erstellt am ${dayjs(item.createdAt).format(
                "DD.MM.YYYY"
              )}`}
              image={{ uri: item?.thumbnailUrl }}
              file={item}
              onRemove={() => deleteFile(item._id)}
              onSelect={
                route?.params?.onSelect
                  ? () => {
                      route?.params?.onSelect({ item });
                    }
                  : null
              }
            />
          );
        }}
        grid
      />
    </Page>
  );
}

import React from "react";
import { Flex, Icon, Touchable, Avatar, Animate } from "unikit";
import { Modal, Pressable, TouchableWithoutFeedback } from "react-native";

export const langs = {
  de: {
    key: "de",
    locale: "de-DE",
    label: "deutsch",
    image: require(`../assets/flags/normal/de.svg`),
  },
  en: {
    key: "en",
    locale: "en-US",
    label: "english",
    image: require(`../assets/flags/normal/en.svg`),
  },
  ru: {
    key: "ru",
    label: "русский",
    image: require(`../assets/flags/normal/ru.svg`),
  },
  fr: {
    key: "fr",
    label: "france",
    image: require(`../assets/flags/normal/fr.svg`),
  },
  nl: {
    key: "nl",
    locale: "nl-NL",
    label: "nederlands",
    image: require(`../assets/flags/normal/nl.svg`),
  },
  es: {
    key: "es",
    locale: "es",
    label: "spanish",
    image: require(`../assets/flags/normal/es.svg`),
  },
};

const LangSel = ({ value = ["de"], onChange }) => {
  const [modal, setModal] = React.useState(false);
  return (
    <>
      <Flex bg="surface" p={15} borderRadius={5} w="100%" row wrap>
        {value.map((item) => {
          return (
            <Avatar
              size={30}
              mr={7}
              source={langs[item] ? langs[item].image : langs.de.image}
            />
          );
        })}
        <Pressable onPress={() => setModal(true)}>
          <Icon name="chevronDown" size={16} ml={6} />
        </Pressable>
        <Modal visible={modal} transparent animationType="fade">
          <Touchable
            onPress={() => setModal(false)}
            bg="rgba(0,0,0,0.3)"
            flexCenter
            flex={1}
          >
            <TouchableWithoutFeedback>
              <Animate
                w="100%"
                maxWidth={500}
                bg="surface"
                from={{ y: 100 }}
                to={{ y: 0 }}
              >
                <Flex row wrap>
                  {Object.values(langs)
                    .filter((val) => {
                      return value.indexOf(val.key) === -1;
                    })
                    .map((item) => (
                      <Touchable onPress={() => onChange([...value, item.key])}>
                        <Avatar mr={10} size={30} source={item.image} />
                      </Touchable>
                    ))}
                </Flex>
              </Animate>
            </TouchableWithoutFeedback>
          </Touchable>
        </Modal>
      </Flex>
    </>
  );
};

export default LangSel;

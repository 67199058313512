import React, { useMemo, useEffect } from "react";
import { Animated } from "react-native";
import { Path } from "react-native-svg";
import { svgPathProperties } from "svg-path-properties";

import { isWeb } from "../../util";

const AnimatedPath = Animated.createAnimatedComponent(Path);

interface Props {
  d: string;
  animationType?: string;
  duration?: number;
  delay?: number;
  [key: string]: any;
}

export default ({
  d,
  animationType = "timing",
  duration = 1000,
  delay = 0,
  progress = 1,
  ...rest
}: Props) => {
  const length = React.useMemo(() => {
    const properties = new svgPathProperties(d);
    return properties.getTotalLength();
  }, [d]);
  const animatedValue = useMemo(() => new Animated.Value(0), []);

  const animateToProgress = (p: number) => {
    const Animation =
      animationType === "timing" ? Animated.timing : Animated.spring;
    setTimeout(() => {
      Animation(animatedValue, {
        toValue: 1 * p,
        duration,
        useNativeDriver: true,
      }).start();
    }, delay);
  };

  useEffect(() => {
    animateToProgress(progress);
  }, [progress]);

  const t = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [length, 0],
  });

  return (
    <AnimatedPath
      d={d}
      strokeDasharray={length}
      strokeDashoffset={t}
      style={{ opacity: length === null ? 0 : 1 }}
      {...rest}
    />
  );
};

import * as React from "react";
import {
  Grid,
  Animate,
  Text,
  Avatar,
  Flex,
  useTheme,
  Touchable,
  Icon,
} from "unikit";

import { Page } from "components";
import { ProjectSchema } from "../contist-server/lib/schemas";
import { useAppState } from "../AppContext";
import { AppIcon } from "../AppNavigation";

export default function Screen({ navigation: { navigate, dispatch } }) {
  const { user, inRole, callMeteor } = useAppState();
  const theme = useTheme();
  return (
    <Page
      title={"Projekte"}
      scrollViewProps={{ contentContainerStyle: { padding: 50 } }}
      scrollable
    >
      <Grid gap={20} px={20} my={20} min={350}>
        {inRole({ roles: ["GlobalAdmin"] }) ? (
          <Touchable
            onPress={() => {
              navigate("Entry", {
                title: "Neues Projekt",
                mode: "insert",
                schema: ProjectSchema._schema,
                onSubmit: ({ doc, navigation }) => {
                  callMeteor({
                    variables: {
                      name: "createProject",
                      options: { ...doc },
                    },
                    refetchQueries: ["user"],
                  })
                    .then((result) => {
                      console.log({ result });
                      theme.alert({ type: "success", message: "Yeah" });
                      navigation.goBack();
                    })
                    .catch((error) => {
                      theme.alert({ type: "error", message: error.message });
                    });
                },
              });
            }}
          >
            <Animate
              as={Flex}
              w="100%"
              p={30}
              bg="primary:setAlpha:0.1"
              borderWidth={2}
              borderStyle="dashed"
              borderColor="primary"
              alignItems="center"
              shadow={25}
              borderRadius={8}
            >
              <Avatar size={80} bg="primary">
                <Icon name="plus" color="#FFF" size={25} />
              </Avatar>
              <Text
                style={{ textAlign: "center", marginTop: 15 }}
                level={5}
                numberOfLines={1}
              >
                New
              </Text>
            </Animate>
          </Touchable>
        ) : null}
        {user?.userProjects?.map((project, index) => {
          if (!project) return null;

          return (
            <Touchable
              onPress={() => {
                navigate("EntriesStack", {
                  screen: "Entries",
                  params: { projectId: project._id },
                });
              }}
              activeOpacity={0.8}
              key={index}
            >
              <Animate
                as={Flex}
                delay={index * 150}
                w="100%"
                p={30}
                bg="surface"
                alignItems="center"
                shadow={25}
                borderRadius={8}
              >
                <AppIcon
                  size={80}
                  bg={project?.projectColor || undefined}
                  char={project?.name?.charAt(0)}
                  logo={
                    project.logoFile
                      ? project.logoFile?.thumbnailUrl ||
                        project.logoFile?.data?.secure_url
                      : undefined
                  }
                />

                <Text
                  style={{ textAlign: "center", marginTop: 15 }}
                  level={5}
                  numberOfLines={1}
                >
                  {project.name}
                </Text>
              </Animate>
            </Touchable>
          );
        })}
      </Grid>
    </Page>
  );
}

Screen.navigationOptions = ({ navigation: { getParam }, screenProps }) => ({
  headerTitle: getParam("title", "Projekte"),
});

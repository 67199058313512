import * as React from "react";
import { Avatar, useTheme, Icon, Text, Touchable, Flex } from "unikit";
import * as ImagePicker from "expo-image-picker";
import { useDropzone } from "react-dropzone";

import { useAppState } from "../AppContext";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default function Screen({
  onSuccess,
  label = "Bild hochladen",
  fileId,
}) {
  const { callMeteor, project } = useAppState();
  const theme = useTheme();

  const onDrop = React.useCallback((acceptedFiles) => {
    acceptedFiles.map(async (file) => {
      const alertKey = theme.alert({
        type: "surface",
        loading: true,
        timeout: false,
        message: "",
      });

      const base64 = await toBase64(file);
      const result = await callMeteor({
        variables: {
          name: "createFile",
          options: {
            projectId: project._id,
            name: file.name,
            base64,
            width: file.width,
            height: file.height,
            fileId,
          },
        },
      });
      console.log({ result });
      if (onSuccess) onSuccess();
      theme.alert({
        key: alertKey,
        loading: false,
        type: "success",
        message: "Datei gespeichert",
        timeout: 2000,
      });
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Flex p={15}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <Avatar size={50} shadow={5} bg="primary">
              <Icon name={"cloud"} color="#FFF" size={24} />
            </Avatar>

            <Text mt={12} color="primary">
              {label}
            </Text>
          </>
        )}
      </div>
    </Flex>
  );
}

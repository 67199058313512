export default {
  activity: ["M22 12 L18 12 L15 21 L9 3 L6 12 L2 12"],
  airplay: [
    "M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1",
    "M12 15 L17 21 L7 21 L12 15 Z",
  ],
  "alert-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M12 8 L12 12",
    "M12 16 L12.01 16",
  ],
  "alert-octagon": [
    "M7.86 2 L16.14 2 L22 7.86 L22 16.14 L16.14 22 L7.86 22 L2 16.14 L2 7.86 L7.86 2 Z",
    "M12 8 L12 12",
    "M12 16 L12.01 16",
  ],
  "alert-triangle": [
    "M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z",
    "M12 9 L12 13",
    "M12 17 L12.01 17",
  ],
  "align-center": [
    "M18 10 L6 10",
    "M21 6 L3 6",
    "M21 14 L3 14",
    "M18 18 L6 18",
  ],
  "align-justify": [
    "M21 10 L3 10",
    "M21 6 L3 6",
    "M21 14 L3 14",
    "M21 18 L3 18",
  ],
  "align-left": ["M17 10 L3 10", "M21 6 L3 6", "M21 14 L3 14", "M17 18 L3 18"],
  "align-right": ["M21 10 L7 10", "M21 6 L3 6", "M21 14 L3 14", "M21 18 L7 18"],
  anchor: [
    "M15 5 A3 3 0 0 1 12 8 A3 3 0 0 1 9 5 A3 3 0 0 1 15 5 z",
    "M12 22 L12 8",
    "M5 12H2a10 10 0 0 0 20 0h-3",
  ],
  archive: ["M21 8 L21 21 L3 21 L3 8", "M1 3 H23 V8 H1 V3 z", "M10 12 L14 12"],
  "at-sign": [
    "M16 12 A4 4 0 0 1 12 16 A4 4 0 0 1 8 12 A4 4 0 0 1 16 12 z",
    "M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94",
  ],
  "arrow-left": ["M19 12H5 M12 19l-7-7l7-7"],
  "arrow-right": ["M5 12h14 M12 5l7 7l-7 7"],
  "arrow-down": ["M12,5v14 M19,12l-7,7l-7-7"],
  "arrow-up": ["M12,19V5 M5,12l7-7l7,7"],
  award: [
    "M19 8 A7 7 0 0 1 12 15 A7 7 0 0 1 5 8 A7 7 0 0 1 19 8 z",
    "M8.21 13.89 L7 23 L12 20 L17 23 L15.79 13.88",
  ],
  aperture: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M14.31 8 L20.05 17.94",
    "M9.69 8 L21.17 8",
    "M7.38 12 L13.12 2.06",
    "M9.69 16 L3.95 6.06",
    "M14.31 16 L2.83 16",
    "M16.62 12 L10.88 21.94",
  ],
  "bar-chart": ["M12 20 L12 10", "M18 20 L18 4", "M6 20 L6 16"],
  battery: [
    "M3 6 H17 A2 2 0 0 1 19 8 V16 A2 2 0 0 1 17 18 H3 A2 2 0 0 1 1 16 V8 A2 2 0 0 1 3 6 z",
    "M23 13 L23 11",
  ],
  "battery-charging": [
    "M5 18H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h3.19M15 6h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-3.19",
    "M23 13 L23 11",
    "M11 6 L7 12 L13 12 L9 18",
  ],
  bell: [
    "M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9",
    "M13.73 21a2 2 0 0 1-3.46 0",
  ],
  "bell-off": [
    "M13.73 21a2 2 0 0 1-3.46 0",
    "M18.63 13A17.89 17.89 0 0 1 18 8",
    "M6.26 6.26A5.86 5.86 0 0 0 6 8c0 7-3 9-3 9h14",
    "M18 8a6 6 0 0 0-9.33-5",
    "M1 1 L23 23",
  ],
  bluetooth: ["M6.5 6.5 L17.5 17.5 L12 23 L12 1 L17.5 6.5 L6.5 17.5"],
  "book-open": [
    "M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z",
    "M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z",
  ],
  book: [
    "M4 19.5A2.5 2.5 0 0 1 6.5 17H20",
    "M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z",
  ],
  bookmark: ["M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"],
  box: [
    "M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z",
    "M3.27 6.96 L12 12.01 L20.73 6.96",
    "M12 22.08 L12 12",
  ],
  briefcase: [
    "M4 7 H20 A2 2 0 0 1 22 9 V19 A2 2 0 0 1 20 21 H4 A2 2 0 0 1 2 19 V9 A2 2 0 0 1 4 7 z",
    "M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16",
  ],
  calendar: [
    "M5 4 H19 A2 2 0 0 1 21 6 V20 A2 2 0 0 1 19 22 H5 A2 2 0 0 1 3 20 V6 A2 2 0 0 1 5 4 z",
    "M16 2 L16 6",
    "M8 2 L8 6",
    "M3 10 L21 10",
  ],
  camera: [
    "M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z",
    "M16 13 A4 4 0 0 1 12 17 A4 4 0 0 1 8 13 A4 4 0 0 1 16 13 z",
  ],
  cast: [
    "M2 16.1A5 5 0 0 1 5.9 20M2 12.05A9 9 0 0 1 9.95 20M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6",
    "M2 20 L2.01 20",
  ],
  cash: [
    "M9,9h10c1.1,0,2,0.9,2,2v6c0,1.1-0.9,2-2,2H9c-1.1,0-2-0.9-2-2v-6C7,9.9,7.9,9,9,9z M14,12c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2S12.9,12,14,12z M17,9V7c0-1.1-0.9-2-2-2H5C3.9,5,3,5.9,3,7v6c0,1.1,0.9,2,2,2h2",
  ],
  check: ["M20,6L9,17l-5-5"],
  "chevron-left": ["M15,18l-6-6l6-6"],
  "chevron-up": ["M18,15l-6-6l-6,6"],
  "chevron-right": ["M9,18l6-6L9,6"],
  "chevron-down": ["M6,9l6,6l6-6"],
  circle: ["M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z"],
  clipboard: [
    "M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2",
    "M9 2 H15 A1 1 0 0 1 16 3 V5 A1 1 0 0 1 15 6 H9 A1 1 0 0 1 8 5 V3 A1 1 0 0 1 9 2 z",
  ],
  clock: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M12 6 L12 12 L16 14",
  ],
  "cloud-drizzle": [
    "M8 19 L8 21",
    "M8 13 L8 15",
    "M16 19 L16 21",
    "M16 13 L16 15",
    "M12 21 L12 23",
    "M12 15 L12 17",
    "M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25",
  ],
  "cloud-lightning": [
    "M19 16.9A5 5 0 0 0 18 7h-1.26a8 8 0 1 0-11.62 9",
    "M13 11 L9 17 L15 17 L11 23",
  ],
  "cloud-rain": [
    "M16 13 L16 21",
    "M8 13 L8 21",
    "M12 15 L12 23",
    "M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25",
  ],
  "cloud-snow": [
    "M20 17.58A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.25",
    "M8 16 L8.01 16",
    "M8 20 L8.01 20",
    "M12 18 L12.01 18",
    "M12 22 L12.01 22",
    "M16 16 L16.01 16",
    "M16 20 L16.01 20",
  ],
  cloud: ["M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z"],
  codepen: [
    "M12 2 L22 8.5 L22 15.5 L12 22 L2 15.5 L2 8.5 L12 2 Z",
    "M12 22 L12 15.5",
    "M22 8.5 L12 15.5 L2 8.5",
    "M2 15.5 L12 8.5 L22 15.5",
    "M12 2 L12 8.5",
  ],
  codesandbox: [
    "M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z",
    "M7.5 4.21 L12 6.81 L16.5 4.21",
    "M7.5 19.79 L7.5 14.6 L3 12",
    "M21 12 L16.5 14.6 L16.5 19.79",
    "M3.27 6.96 L12 12.01 L20.73 6.96",
    "M12 22.08 L12 12",
  ],
  code: ["M16 18 L22 12 L16 6", "M8 6 L2 12 L8 18"],
  coffee: [
    "M18 8h1a4 4 0 0 1 0 8h-1",
    "M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z",
    "M6 1 L6 4",
    "M10 1 L10 4",
    "M14 1 L14 4",
  ],
  columns: [
    "M12 3h7a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-7m0-18H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h7m0-18v18",
  ],
  command: [
    "M18 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3H6a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3V6a3 3 0 0 0-3-3 3 3 0 0 0-3 3 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 3 3 0 0 0-3-3z",
  ],
  compass: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M16.24 7.76 L14.12 14.12 L7.76 16.24 L9.88 9.88 L16.24 7.76 Z",
  ],
  copy: [
    "M11 9 H20 A2 2 0 0 1 22 11 V20 A2 2 0 0 1 20 22 H11 A2 2 0 0 1 9 20 V11 A2 2 0 0 1 11 9 z",
    "M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1",
  ],
  "corner-down-left": ["M9 10 L4 15 L9 20", "M20 4v7a4 4 0 0 1-4 4H4"],
  "corner-down-right": ["M15 10 L20 15 L15 20", "M4 4v7a4 4 0 0 0 4 4h12"],
  "corner-left-down": ["M14 15 L9 20 L4 15", "M20 4h-7a4 4 0 0 0-4 4v12"],
  "corner-left-up": ["M14 9 L9 4 L4 9", "M20 20h-7a4 4 0 0 1-4-4V4"],
  "corner-right-down": ["M10 15 L15 20 L20 15", "M4 4h7a4 4 0 0 1 4 4v12"],
  "corner-right-up": ["M10 9 L15 4 L20 9", "M4 20h7a4 4 0 0 0 4-4V4"],
  "corner-up-left": ["M9 14 L4 9 L9 4", "M20 20v-7a4 4 0 0 0-4-4H4"],
  "corner-up-right": ["M15 14 L20 9 L15 4", "M4 20v-7a4 4 0 0 1 4-4h12"],
  cpu: [
    "M6 4 H18 A2 2 0 0 1 20 6 V18 A2 2 0 0 1 18 20 H6 A2 2 0 0 1 4 18 V6 A2 2 0 0 1 6 4 z",
    "M9 9 H15 V15 H9 V9 z",
    "M9 1 L9 4",
    "M15 1 L15 4",
    "M9 20 L9 23",
    "M15 20 L15 23",
    "M20 9 L23 9",
    "M20 14 L23 14",
    "M1 9 L4 9",
    "M1 14 L4 14",
  ],
  "credit-card": [
    "M3 4 H21 A2 2 0 0 1 23 6 V18 A2 2 0 0 1 21 20 H3 A2 2 0 0 1 1 18 V6 A2 2 0 0 1 3 4 z",
    "M1 10 L23 10",
  ],
  crop: ["M6.13 1L6 16a2 2 0 0 0 2 2h15", "M1 6.13L16 6a2 2 0 0 1 2 2v15"],
  crosshair: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M22 12 L18 12",
    "M6 12 L2 12",
    "M12 6 L12 2",
    "M12 22 L12 18",
  ],
  database: [
    "M21 5 A9 3 0 0 1 12 8 A9 3 0 0 1 3 5 A9 3 0 0 1 21 5 z",
    "M21 12c0 1.66-4 3-9 3s-9-1.34-9-3",
    "M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5",
  ],
  delete: [
    "M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z",
    "M18 9 L12 15",
    "M12 9 L18 15",
  ],
  disc: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M15 12 A3 3 0 0 1 12 15 A3 3 0 0 1 9 12 A3 3 0 0 1 15 12 z",
  ],
  "dollar-sign": [
    "M12 1 L12 23",
    "M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6",
  ],
  droplet: ["M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z"],
  edit: [
    "M17,3c1.1-1.1,2.9-1.1,4,0s1.1,2.9,0,4l0,0L7.5,20.5L2,22l1.5-5.5L17,3z",
  ],
  eye: [
    "M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z",
    "M15 12 A3 3 0 0 1 12 15 A3 3 0 0 1 9 12 A3 3 0 0 1 15 12 z",
  ],
  "eye-off": [
    "M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24",
    "M1 1 L23 23",
  ],
  "eye-check": [
    "M12 19c-4 0 -7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7c-.42 .736 -.858 1.414 -1.311 2.033",
    "M15 19l2 2l4 -4",
    "M 12, 12 m -2, 0 a 2,2 0 1,0 4,0 a 2,2 0 1,0 -4,0",
  ],
  "external-link": [
    "M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6",
    "M15 3 L21 3 L21 9",
    "M10 14 L21 3",
  ],
  facebook: [
    "M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z",
  ],
  "finger-print": [
    "M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3",
    "M8 11a4 4 0 0 1 8 0v1a10 10 0 0 0 2 6",
    "M12 11v2a14 14 0 0 0 2.5 8",
    "M8 15a18 18 0 0 0 1.8 6",
    "M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 12 -6.95",
  ],
  "face-id": [
    "M4 8v-2a2 2 0 0 1 2 -2h2",
    "M4 16v2a2 2 0 0 0 2 2h2",
    "M16 4h2a2 2 0 0 1 2 2v2",
    "M16 20h2a2 2 0 0 0 2 -2v-2",
    "M9.5 15a3.5 3.5 0 0 0 5 0",
    "M10 10 T 10 11 Z",
    "M14 10 T 14 11 Z",
  ],
  bandage: [
    "M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7",
    "M14 12 T 14 12.01 Z",
    "M10 12 T 10 12.01 Z",
    "M12 10 T 12 10.01 Z",
    "M12 14 T 12 14.01 Z",
  ],
  "fast-forward": ["M13 19 L22 12 L13 5 L13 19 Z", "M2 19 L11 12 L2 5 L2 19 Z"],
  figma: [
    "M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z",
    "M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z",
    "M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z",
    "M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z",
    "M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z",
  ],
  "file-minus": [
    "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z",
    "M14 2 L14 8 L20 8",
    "M9 15 L15 15",
  ],
  "file-plus": [
    "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z",
    "M14 2 L14 8 L20 8",
    "M12 18 L12 12",
    "M9 15 L15 15",
  ],
  "file-text": [
    "M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z",
    "M14 2 L14 8 L20 8",
    "M16 13 L8 13",
    "M16 17 L8 17",
    "M10 9 L9 9 L8 9",
  ],
  film: [
    "M4.18 2 H19.82 A2.18 2.18 0 0 1 22 4.18 V19.82 A2.18 2.18 0 0 1 19.82 22 H4.18 A2.18 2.18 0 0 1 2 19.82 V4.18 A2.18 2.18 0 0 1 4.18 2 z",
    "M7 2 L7 22",
    "M17 2 L17 22",
    "M2 12 L22 12",
    "M2 7 L7 7",
    "M2 17 L7 17",
    "M17 17 L22 17",
    "M17 7 L22 7",
  ],
  filter: ["M22 3 L2 3 L10 12.46 L10 19 L14 21 L14 12.46 L22 3 Z"],
  flag: [
    "M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z",
    "M4 22 L4 15",
  ],
  "folder-minus": [
    "M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z",
    "M9 14 L15 14",
  ],
  "folder-plus": [
    "M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z",
    "M12 11 L12 17",
    "M9 14 L15 14",
  ],
  folder: [
    "M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z",
  ],
  framer: ["M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"],
  frown: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M16 16s-1.5-2-4-2-4 2-4 2",
    "M9 9 L9.01 9",
    "M15 9 L15.01 9",
  ],
  gift: [
    "M20 12 L20 22 L4 22 L4 12",
    "M2 7 H22 V12 H2 V7 z",
    "M12 22 L12 7",
    "M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z",
    "M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z",
  ],
  "git-branch": [
    "M6 3 L6 15",
    "M21 6 A3 3 0 0 1 18 9 A3 3 0 0 1 15 6 A3 3 0 0 1 21 6 z",
    "M9 18 A3 3 0 0 1 6 21 A3 3 0 0 1 3 18 A3 3 0 0 1 9 18 z",
    "M18 9a9 9 0 0 1-9 9",
  ],
  "git-commit": [
    "M16 12 A4 4 0 0 1 12 16 A4 4 0 0 1 8 12 A4 4 0 0 1 16 12 z",
    "M1.05 12 L7 12",
    "M17.01 12 L22.96 12",
  ],
  "git-merge": [
    "M21 18 A3 3 0 0 1 18 21 A3 3 0 0 1 15 18 A3 3 0 0 1 21 18 z",
    "M9 6 A3 3 0 0 1 6 9 A3 3 0 0 1 3 6 A3 3 0 0 1 9 6 z",
    "M6 21V9a9 9 0 0 0 9 9",
  ],
  "git-pull-request": [
    "M21 18 A3 3 0 0 1 18 21 A3 3 0 0 1 15 18 A3 3 0 0 1 21 18 z",
    "M9 6 A3 3 0 0 1 6 9 A3 3 0 0 1 3 6 A3 3 0 0 1 9 6 z",
    "M13 6h3a2 2 0 0 1 2 2v7",
    "M6 9 L6 21",
  ],
  github: [
    "M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22",
  ],
  gitlab: [
    "M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z",
  ],
  globe: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M2 12 L22 12",
    "M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z",
  ],
  grid: ["M3,3h7v7H3V3z M14,3h7v7h-7V3z M14,14h7v7h-7V14z M3,14h7v7H3V14z"],
  "hard-drive": [
    "M22 12 L2 12",
    "M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z",
    "M6 16 L6.01 16",
    "M10 16 L10.01 16",
  ],
  hash: ["M4 9 L20 9", "M4 15 L20 15", "M10 3 L8 21", "M16 3 L14 21"],
  headphones: [
    "M3 18v-6a9 9 0 0 1 18 0v6",
    "M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z",
  ],
  heart: [
    "M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z",
  ],
  "help-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3",
    "M12 17 L12.01 17",
  ],
  hexagon: [
    "M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z",
  ],
  home: [
    "M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z",
    "M9 22 L9 12 L15 12 L15 22",
  ],
  image: [
    "M5 3 H19 A2 2 0 0 1 21 5 V19 A2 2 0 0 1 19 21 H5 A2 2 0 0 1 3 19 V5 A2 2 0 0 1 5 3 z",
    "M10 8.5 A1.5 1.5 0 0 1 8.5 10 A1.5 1.5 0 0 1 7 8.5 A1.5 1.5 0 0 1 10 8.5 z",
    "M21 15 L16 10 L5 21",
  ],
  inbox: [
    "M22 12 L16 12 L14 15 L10 15 L8 12 L2 12",
    "M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z",
  ],
  instagram: [
    "M7 2 H17 A5 5 0 0 1 22 7 V17 A5 5 0 0 1 17 22 H7 A5 5 0 0 1 2 17 V7 A5 5 0 0 1 7 2 z",
    "M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z",
    "M17.5 6.5 L17.51 6.5",
  ],
  key: [
    "M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4",
  ],
  layers: [
    "M12 2 L2 7 L12 12 L22 7 L12 2 Z",
    "M2 17 L12 22 L22 17",
    "M2 12 L12 17 L22 12",
  ],
  layout: [
    "M5 3 H19 A2 2 0 0 1 21 5 V19 A2 2 0 0 1 19 21 H5 A2 2 0 0 1 3 19 V5 A2 2 0 0 1 5 3 z",
    "M3 9 L21 9",
    "M9 21 L9 9",
  ],
  "life-buoy": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M16 12 A4 4 0 0 1 12 16 A4 4 0 0 1 8 12 A4 4 0 0 1 16 12 z",
    "M4.93 4.93 L9.17 9.17",
    "M14.83 14.83 L19.07 19.07",
    "M14.83 9.17 L19.07 4.93",
    "M14.83 9.17 L18.36 5.64",
    "M4.93 19.07 L9.17 14.83",
  ],
  link: [
    "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71",
    "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71",
  ],
  linkedin: [
    "M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z",
    "M2 9 H6 V21 H2 V9 z",
    "M6 4 A2 2 0 0 1 4 6 A2 2 0 0 1 2 4 A2 2 0 0 1 6 4 z",
  ],
  list: [
    "M8 6 L21 6",
    "M8 12 L21 12",
    "M8 18 L21 18",
    "M3 6 L3.01 6",
    "M3 12 L3.01 12",
    "M3 18 L3.01 18",
  ],
  lock: [
    "M5 11 H19 A2 2 0 0 1 21 13 V20 A2 2 0 0 1 19 22 H5 A2 2 0 0 1 3 20 V13 A2 2 0 0 1 5 11 z",
    "M7 11V7a5 5 0 0 1 10 0v4",
  ],
  "log-in": [
    "M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4",
    "M10 17 L15 12 L10 7",
    "M15 12 L3 12",
  ],
  "log-out": [
    "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4",
    "M16 17 L21 12 L16 7",
    "M21 12 L9 12",
  ],
  mail: [
    "M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z",
    "M22 6 L12 13 L2 6",
  ],
  "map-pin": [
    "M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z",
    "M15 10 A3 3 0 0 1 12 13 A3 3 0 0 1 9 10 A3 3 0 0 1 15 10 z",
  ],
  map: [
    "M1 6 L1 22 L8 18 L16 22 L23 18 L23 2 L16 6 L8 2 L1 6 Z",
    "M8 2 L8 18",
    "M16 6 L16 22",
  ],
  maximize: [
    "M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3",
  ],
  meh: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M8 15 L16 15",
    "M9 9 L9.01 9",
    "M15 9 L15.01 9",
  ],
  menu: ["M3 12 L21 12", "M3 6 L21 6", "M3 18 L21 18"],
  "message-circle": [
    "M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z",
  ],
  "message-square": [
    "M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z",
  ],
  "mic-off": [
    "M1 1 L23 23",
    "M9 9v3a3 3 0 0 0 5.12 2.12M15 9.34V4a3 3 0 0 0-5.94-.6",
    "M17 16.95A7 7 0 0 1 5 12v-2m14 0v2a7 7 0 0 1-.11 1.23",
    "M12 19 L12 23",
    "M8 23 L16 23",
  ],
  mic: [
    "M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z",
    "M19 10v2a7 7 0 0 1-14 0v-2",
    "M12 19 L12 23",
    "M8 23 L16 23",
  ],
  minimize: [
    "M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3",
  ],
  minus: ["M5 12 L19 12"],
  monitor: [
    "M4 3 H20 A2 2 0 0 1 22 5 V15 A2 2 0 0 1 20 17 H4 A2 2 0 0 1 2 15 V5 A2 2 0 0 1 4 3 z",
    "M8 21 L16 21",
    "M12 17 L12 21",
  ],
  moon: ["M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"],
  "more-horizontal": [
    "M13 12 A1 1 0 0 1 12 13 A1 1 0 0 1 11 12 A1 1 0 0 1 13 12 z",
    "M20 12 A1 1 0 0 1 19 13 A1 1 0 0 1 18 12 A1 1 0 0 1 20 12 z",
    "M6 12 A1 1 0 0 1 5 13 A1 1 0 0 1 4 12 A1 1 0 0 1 6 12 z",
  ],
  "more-vertical": [
    "M13 12 A1 1 0 0 1 12 13 A1 1 0 0 1 11 12 A1 1 0 0 1 13 12 z",
    "M13 5 A1 1 0 0 1 12 6 A1 1 0 0 1 11 5 A1 1 0 0 1 13 5 z",
    "M13 19 A1 1 0 0 1 12 20 A1 1 0 0 1 11 19 A1 1 0 0 1 13 19 z",
  ],
  "mouse-pointer": ["M3 3l7.07 16.97 2.51-7.39 7.39-2.51L3 3z", "M13 13l6 6"],
  move: [
    "M5 9 L2 12 L5 15",
    "M9 5 L12 2 L15 5",
    "M15 19 L12 22 L9 19",
    "M19 9 L22 12 L19 15",
    "M2 12 L22 12",
    "M12 2 L12 22",
  ],
  music: [
    "M9 18V5l12-2v13",
    "M9 18 A3 3 0 0 1 6 21 A3 3 0 0 1 3 18 A3 3 0 0 1 9 18 z",
    "M21 16 A3 3 0 0 1 18 19 A3 3 0 0 1 15 16 A3 3 0 0 1 21 16 z",
  ],
  navigation: ["M3 11 L22 2 L13 21 L11 13 L3 11 Z"],
  octagon: [
    "M7.86 2 L16.14 2 L22 7.86 L22 16.14 L16.14 22 L7.86 22 L2 16.14 L2 7.86 L7.86 2 Z",
  ],
  package: [
    "M16.5 9.4 L7.5 4.21",
    "M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z",
    "M3.27 6.96 L12 12.01 L20.73 6.96",
    "M12 22.08 L12 12",
  ],
  paperclip: [
    "M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48",
  ],
  pause: ["M6 4 H10 V20 H6 V4 z", "M14 4 H18 V20 H14 V4 z"],
  "pause-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M10 15 L10 9",
    "M14 15 L14 9",
  ],
  "pen-tool": [
    "M12 19l7-7 3 3-7 7-3-3z",
    "M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z",
    "M2 2l7.586 7.586",
    "M13 11 A2 2 0 0 1 11 13 A2 2 0 0 1 9 11 A2 2 0 0 1 13 11 z",
  ],
  percent: [
    "M19 5 L5 19",
    "M9 6.5 A2.5 2.5 0 0 1 6.5 9 A2.5 2.5 0 0 1 4 6.5 A2.5 2.5 0 0 1 9 6.5 z",
    "M20 17.5 A2.5 2.5 0 0 1 17.5 20 A2.5 2.5 0 0 1 15 17.5 A2.5 2.5 0 0 1 20 17.5 z",
  ],
  "phone-call": [
    "M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  "phone-forwarded": [
    "M19 1 L23 5 L19 9",
    "M15 5 L23 5",
    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  "phone-incoming": [
    "M16 2 L16 8 L22 8",
    "M23 1 L16 8",
    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  "phone-missed": [
    "M23 1 L17 7",
    "M17 1 L23 7",
    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  "phone-off": [
    "M10.68 13.31a16 16 0 0 0 3.41 2.6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7 2 2 0 0 1 1.72 2v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.42 19.42 0 0 1-3.33-2.67m-2.67-3.34a19.79 19.79 0 0 1-3.07-8.63A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91",
    "M23 1 L1 23",
  ],
  "phone-outgoing": [
    "M23 7 L23 1 L17 1",
    "M16 8 L23 1",
    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  phone: [
    "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z",
  ],
  play: ["M5 3 L19 12 L5 21 L5 3 Z"],
  "pie-chart": [
    "M21.21 15.89A10 10 0 1 1 8 2.83",
    "M22 12A10 10 0 0 0 12 2v10z",
  ],
  "play-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M10 8 L16 12 L10 16 L10 8 Z",
  ],
  plus: ["M12 5 L12 19", "M5 12 L19 12"],
  "plus-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M12 8 L12 16",
    "M8 12 L16 12",
  ],
  "plus-square": [
    "M5 3 H19 A2 2 0 0 1 21 5 V19 A2 2 0 0 1 19 21 H5 A2 2 0 0 1 3 19 V5 A2 2 0 0 1 5 3 z",
    "M12 8 L12 16",
    "M8 12 L16 12",
  ],
  pocket: [
    "M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z",
    "M8 10 L12 14 L16 10",
  ],
  power: ["M18.36 6.64a9 9 0 1 1-12.73 0", "M12 2 L12 12"],
  printer: [
    "M6 9 L6 2 L18 2 L18 9",
    "M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2",
    "M6 14 H18 V22 H6 V14 z",
  ],
  radio: [
    "M14 12 A2 2 0 0 1 12 14 A2 2 0 0 1 10 12 A2 2 0 0 1 14 12 z",
    "M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14",
  ],
  "refresh-cw": [
    "M23 4 L23 10 L17 10",
    "M1 20 L1 14 L7 14",
    "M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15",
  ],
  "refresh-ccw": [
    "M1 4 L1 10 L7 10",
    "M23 20 L23 14 L17 14",
    "M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15",
  ],
  repeat: [
    "M17 1 L21 5 L17 9",
    "M3 11V9a4 4 0 0 1 4-4h14",
    "M7 23 L3 19 L7 15",
    "M21 13v2a4 4 0 0 1-4 4H3",
  ],
  rewind: ["M11 19 L2 12 L11 5 L11 19 Z", "M22 19 L13 12 L22 5 L22 19 Z"],
  "rotate-ccw": ["M1 4 L1 10 L7 10", "M3.51 15a9 9 0 1 0 2.13-9.36L1 10"],
  "rotate-cw": ["M23 4 L23 10 L17 10", "M20.49 15a9 9 0 1 1-2.12-9.36L23 10"],
  rss: [
    "M4 11a9 9 0 0 1 9 9",
    "M4 4a16 16 0 0 1 16 16",
    "M6 19 A1 1 0 0 1 5 20 A1 1 0 0 1 4 19 A1 1 0 0 1 6 19 z",
  ],
  save: [
    "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z",
    "M17 21 L17 13 L7 13 L7 21",
    "M7 3 L7 8 L15 8",
  ],
  scissors: [
    "M9 6 A3 3 0 0 1 6 9 A3 3 0 0 1 3 6 A3 3 0 0 1 9 6 z",
    "M9 18 A3 3 0 0 1 6 21 A3 3 0 0 1 3 18 A3 3 0 0 1 9 18 z",
    "M20 4 L8.12 15.88",
    "M14.47 14.48 L20 20",
    "M8.12 8.12 L12 12",
  ],
  search: [
    "M19 11 A8 8 0 0 1 11 19 A8 8 0 0 1 3 11 A8 8 0 0 1 19 11 z",
    "M21 21 L16.65 16.65",
  ],
  send: ["M22 2 L11 13", "M22 2 L15 22 L11 13 L2 9 L22 2 Z"],
  settings: [
    "M15 12 A3 3 0 0 1 12 15 A3 3 0 0 1 9 12 A3 3 0 0 1 15 12 z",
    "M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z",
  ],
  share: [
    "M4,12v8c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-8 M16,6l-4-4L8,6 M12,2v13",
  ],
  shield: ["M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"],
  "shield-off": [
    "M19.69 14a6.9 6.9 0 0 0 .31-2V5l-8-3-3.16 1.18",
    "M4.73 4.73L4 5v7c0 6 8 10 8 10a20.29 20.29 0 0 0 5.62-4.38",
    "M1 1 L23 23",
  ],
  "shopping-bag": [
    "M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z",
    "M3 6 L21 6",
    "M16 10a4 4 0 0 1-8 0",
  ],
  "shopping-cart": [
    "M10 21 A1 1 0 0 1 9 22 A1 1 0 0 1 8 21 A1 1 0 0 1 10 21 z",
    "M21 21 A1 1 0 0 1 20 22 A1 1 0 0 1 19 21 A1 1 0 0 1 21 21 z",
    "M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6",
  ],
  shuffle: [
    "M16 3 L21 3 L21 8",
    "M4 20 L21 3",
    "M21 16 L21 21 L16 21",
    "M15 15 L21 21",
    "M4 4 L9 9",
  ],
  "skip-back": ["M19 20 L9 12 L19 4 L19 20 Z", "M5 19 L5 5"],
  "skip-forward": ["M5 4 L15 12 L5 20 L5 4 Z", "M19 5 L19 19"],
  slack: [
    "M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z",
    "M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z",
    "M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z",
    "M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z",
    "M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z",
    "M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z",
    "M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z",
    "M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z",
  ],
  slash: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M4.93 4.93 L19.07 19.07",
  ],
  sliders: [
    "M4 21 L4 14",
    "M4 10 L4 3",
    "M12 21 L12 12",
    "M12 8 L12 3",
    "M20 21 L20 16",
    "M20 12 L20 3",
    "M1 14 L7 14",
    "M9 8 L15 8",
    "M17 16 L23 16",
  ],
  smartphone: [
    "M7 2 H17 A2 2 0 0 1 19 4 V20 A2 2 0 0 1 17 22 H7 A2 2 0 0 1 5 20 V4 A2 2 0 0 1 7 2 z",
    "M12 18 L12.01 18",
  ],
  smile: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M8 14s1.5 2 4 2 4-2 4-2",
    "M9 9 L9.01 9",
    "M15 9 L15.01 9",
  ],
  speaker: [
    "M6 2 H18 A2 2 0 0 1 20 4 V20 A2 2 0 0 1 18 22 H6 A2 2 0 0 1 4 20 V4 A2 2 0 0 1 6 2 z",
    "M16 14 A4 4 0 0 1 12 18 A4 4 0 0 1 8 14 A4 4 0 0 1 16 14 z",
    "M12 6 L12.01 6",
  ],
  star: [
    "M12 2 L15.09 8.26 L22 9.27 L17 14.14 L18.18 21.02 L12 17.77 L5.82 21.02 L7 14.14 L2 9.27 L8.91 8.26 L12 2 Z",
  ],
  "stop-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M9 9 H15 V15 H9 V9 z",
  ],
  sun: [
    "M17 12 A5 5 0 0 1 12 17 A5 5 0 0 1 7 12 A5 5 0 0 1 17 12 z",
    "M12 1 L12 3",
    "M12 21 L12 23",
    "M4.22 4.22 L5.64 5.64",
    "M18.36 18.36 L19.78 19.78",
    "M1 12 L3 12",
    "M21 12 L23 12",
    "M4.22 19.78 L5.64 18.36",
    "M18.36 5.64 L19.78 4.22",
  ],
  sunrise: [
    "M17 18a5 5 0 0 0-10 0",
    "M12 2 L12 9",
    "M4.22 10.22 L5.64 11.64",
    "M1 18 L3 18",
    "M21 18 L23 18",
    "M18.36 11.64 L19.78 10.22",
    "M23 22 L1 22",
    "M8 6 L12 2 L16 6",
  ],
  sunset: [
    "M17 18a5 5 0 0 0-10 0",
    "M12 9 L12 2",
    "M4.22 10.22 L5.64 11.64",
    "M1 18 L3 18",
    "M21 18 L23 18",
    "M18.36 11.64 L19.78 10.22",
    "M23 22 L1 22",
    "M16 5 L12 9 L8 5",
  ],
  tablet: [
    "M6 2 H18 A2 2 0 0 1 20 4 V20 A2 2 0 0 1 18 22 H6 A2 2 0 0 1 4 20 V4 A2 2 0 0 1 6 2 z",
    "M12 18 L12.01 18",
  ],
  tag: [
    "M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z",
    "M7 7 L7.01 7",
  ],
  target: [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M18 12 A6 6 0 0 1 12 18 A6 6 0 0 1 6 12 A6 6 0 0 1 18 12 z",
    "M14 12 A2 2 0 0 1 12 14 A2 2 0 0 1 10 12 A2 2 0 0 1 14 12 z",
  ],
  terminal: ["M4 17 L10 11 L4 5", "M12 19 L20 19"],
  thermometer: ["M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z"],
  "thumbs-down": [
    "M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17",
  ],
  "thumbs-up": [
    "M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3",
  ],
  "toggle-left": [
    "M8 5 H16 A7 7 0 0 1 23 12 V12 A7 7 0 0 1 16 19 H8 A7 7 0 0 1 1 12 V12 A7 7 0 0 1 8 5 z",
    "M11 12 A3 3 0 0 1 8 15 A3 3 0 0 1 5 12 A3 3 0 0 1 11 12 z",
  ],
  "toggle-right": [
    "M8 5 H16 A7 7 0 0 1 23 12 V12 A7 7 0 0 1 16 19 H8 A7 7 0 0 1 1 12 V12 A7 7 0 0 1 8 5 z",
    "M19 12 A3 3 0 0 1 16 15 A3 3 0 0 1 13 12 A3 3 0 0 1 19 12 z",
  ],
  tool: [
    "M14.7 6.3a1 1 0 0 0 0 1.4l1.6 1.6a1 1 0 0 0 1.4 0l3.77-3.77a6 6 0 0 1-7.94 7.94l-6.91 6.91a2.12 2.12 0 0 1-3-3l6.91-6.91a6 6 0 0 1 7.94-7.94l-3.76 3.76z",
  ],
  trash: [
    "M3 6 L5 6 L21 6",
    "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2",
  ],
  triangle: [
    "M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z",
  ],
  truck: [
    "M1 3 H16 V16 H1 V3 z",
    "M16 8 L20 8 L23 11 L23 16 L16 16 L16 8 Z",
    "M8 18.5 A2.5 2.5 0 0 1 5.5 21 A2.5 2.5 0 0 1 3 18.5 A2.5 2.5 0 0 1 8 18.5 z",
    "M21 18.5 A2.5 2.5 0 0 1 18.5 21 A2.5 2.5 0 0 1 16 18.5 A2.5 2.5 0 0 1 21 18.5 z",
  ],
  tv: [
    "M4 7 H20 A2 2 0 0 1 22 9 V20 A2 2 0 0 1 20 22 H4 A2 2 0 0 1 2 20 V9 A2 2 0 0 1 4 7 z",
    "M17 2 L12 7 L7 2",
  ],
  twitch: ["M21 2H3v16h5v4l4-4h5l4-4V2zm-10 9V7m5 4V7"],
  twitter: [
    "M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z",
  ],
  type: ["M4 7 L4 4 L20 4 L20 7", "M9 20 L15 20", "M12 4 L12 20"],
  umbrella: ["M23 12a11.05 11.05 0 0 0-22 0zm-5 7a3 3 0 0 1-6 0v-7"],
  unlock: [
    "M5 11 H19 A2 2 0 0 1 21 13 V20 A2 2 0 0 1 19 22 H5 A2 2 0 0 1 3 20 V13 A2 2 0 0 1 5 11 z",
    "M7 11V7a5 5 0 0 1 9.9-1",
  ],
  "user-check": [
    "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
    "M12.5 7 A4 4 0 0 1 8.5 11 A4 4 0 0 1 4.5 7 A4 4 0 0 1 12.5 7 z",
    "M17 11 L19 13 L23 9",
  ],
  "user-minus": [
    "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
    "M12.5 7 A4 4 0 0 1 8.5 11 A4 4 0 0 1 4.5 7 A4 4 0 0 1 12.5 7 z",
    "M23 11 L17 11",
  ],
  "user-plus": [
    "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
    "M12.5 7 A4 4 0 0 1 8.5 11 A4 4 0 0 1 4.5 7 A4 4 0 0 1 12.5 7 z",
    "M20 8 L20 14",
    "M23 11 L17 11",
  ],
  "user-x": [
    "M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
    "M12.5 7 A4 4 0 0 1 8.5 11 A4 4 0 0 1 4.5 7 A4 4 0 0 1 12.5 7 z",
    "M18 8 L23 13",
    "M23 8 L18 13",
  ],
  user: [
    "M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2",
    "M16 7 A4 4 0 0 1 12 11 A4 4 0 0 1 8 7 A4 4 0 0 1 16 7 z",
  ],
  users: [
    "M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2",
    "M13 7 A4 4 0 0 1 9 11 A4 4 0 0 1 5 7 A4 4 0 0 1 13 7 z",
    "M23 21v-2a4 4 0 0 0-3-3.87",
    "M16 3.13a4 4 0 0 1 0 7.75",
  ],
  "video-off": [
    "M16 16v1a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h2m5.66 0H14a2 2 0 0 1 2 2v3.34l1 1L23 7v10",
    "M1 1 L23 23",
  ],
  video: [
    "M23 7 L16 12 L23 17 L23 7 Z",
    "M3 5 H14 A2 2 0 0 1 16 7 V17 A2 2 0 0 1 14 19 H3 A2 2 0 0 1 1 17 V7 A2 2 0 0 1 3 5 z",
  ],
  voicemail: [
    "M10 11.5 A4.5 4.5 0 0 1 5.5 16 A4.5 4.5 0 0 1 1 11.5 A4.5 4.5 0 0 1 10 11.5 z",
    "M23 11.5 A4.5 4.5 0 0 1 18.5 16 A4.5 4.5 0 0 1 14 11.5 A4.5 4.5 0 0 1 23 11.5 z",
    "M5.5 16 L18.5 16",
  ],
  volume: ["M11 5 L6 9 L2 9 L2 15 L6 15 L11 19 L11 5 Z"],
  "volume-x": [
    "M11 5 L6 9 L2 9 L2 15 L6 15 L11 19 L11 5 Z",
    "M23 9 L17 15",
    "M17 9 L23 15",
  ],
  watch: [
    "M19 12 A7 7 0 0 1 12 19 A7 7 0 0 1 5 12 A7 7 0 0 1 19 12 z",
    "M12 9 L12 12 L13.5 13.5",
    "M16.51 17.35l-.35 3.83a2 2 0 0 1-2 1.82H9.83a2 2 0 0 1-2-1.82l-.35-3.83m.01-10.7l.35-3.83A2 2 0 0 1 9.83 1h4.35a2 2 0 0 1 2 1.82l.35 3.83",
  ],
  "wifi-off": [
    "M1 1 L23 23",
    "M16.72 11.06A10.94 10.94 0 0 1 19 12.55",
    "M5 12.55a10.94 10.94 0 0 1 5.17-2.39",
    "M10.71 5.05A16 16 0 0 1 22.58 9",
    "M1.42 9a15.91 15.91 0 0 1 4.7-2.88",
    "M8.53 16.11a6 6 0 0 1 6.95 0",
    "M12 20 L12.01 20",
  ],
  wifi: [
    "M5 12.55a11 11 0 0 1 14.08 0",
    "M1.42 9a16 16 0 0 1 21.16 0",
    "M8.53 16.11a6 6 0 0 1 6.95 0",
    "M12 20 L12.01 20",
  ],
  wind: [
    "M9.59 4.59A2 2 0 1 1 11 8H2m10.59 11.41A2 2 0 1 0 14 16H2m15.73-8.27A2.5 2.5 0 1 1 19.5 12H2",
  ],
  "x-circle": [
    "M22 12 A10 10 0 0 1 12 22 A10 10 0 0 1 2 12 A10 10 0 0 1 22 12 z",
    "M15 9 L9 15",
    "M9 9 L15 15",
  ],
  "x-octagon": [
    "M7.86 2 L16.14 2 L22 7.86 L22 16.14 L16.14 22 L7.86 22 L2 16.14 L2 7.86 L7.86 2 Z",
    "M15 9 L9 15",
    "M9 9 L15 15",
  ],
  "x-square": [
    "M5 3 H19 A2 2 0 0 1 21 5 V19 A2 2 0 0 1 19 21 H5 A2 2 0 0 1 3 19 V5 A2 2 0 0 1 5 3 z",
    "M9 9 L15 15",
    "M15 9 L9 15",
  ],
  x: ["M18 6 L6 18", "M6 6 L18 18"],
  youtube: [
    "M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z",
    "M9.75 15.02 L15.5 11.75 L9.75 8.48 L9.75 15.02 Z",
  ],
  "zap-off": [
    "M12.41 6.75 L13 2 L10.57 4.92",
    "M18.57 12.91 L21 10 L15.66 10",
    "M8 8 L3 14 L12 14 L11 22 L16 16",
    "M1 1 L23 23",
  ],
  zap: ["M13 2 L3 14 L12 14 L11 22 L21 10 L12 10 L13 2 Z"],
  "zoom-in": [
    "M19 11 A8 8 0 0 1 11 19 A8 8 0 0 1 3 11 A8 8 0 0 1 19 11 z",
    "M21 21 L16.65 16.65",
    "M11 8 L11 14",
    "M8 11 L14 11",
  ],
  "zoom-out": [
    "M19 11 A8 8 0 0 1 11 19 A8 8 0 0 1 3 11 A8 8 0 0 1 19 11 z",
    "M21 21 L16.65 16.65",
    "M8 11 L14 11",
  ],
};

import SimpleSchema from "simpl-schema";

function generateId() {
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var stringLength = 25;

  function pickRandom() {
    return possible[Math.floor(Math.random() * possible.length)];
  }

  return (randomString = Array.apply(null, Array(stringLength))
    .map(pickRandom)
    .join(""));
}

import Options from "./options";
SimpleSchema.extendOptions(Options);

const Schema = new SimpleSchema({
  name: {
    type: String,
    label: "Projektname",
    group: "Projektdaten",
  },
  projectColor: {
    type: String,
    label: "Primary Color",
    optional: true,
    component: "Color",
    group: "Projektdaten",
  },
  logo: {
    type: Array,
    label: "Logo",
    optional: true,
    group: "Projektdaten",
    component: "Media",
    minmax: {
      minWidth: 50,
      minHeight: 50,
      maxSize: 1000,
    },
  },
  "logo.$": {
    type: String,
    listed: false,
  },
  apiOperations: {
    type: Number,
    listed: false,
    autoValue: function () {
      if (this.isInsert || this.isUpsert) {
        return 0;
      }
    },
  },
  languages: {
    type: Array,
    label: "Sprachen",
    component: "Lang",
    optional: true,
    defaultValue: ["de"],
    group: "Sprachen",
  },
  "languages.$": {
    type: String,
    listed: false,
  },
  netlifyBuildHook: {
    type: String,
    label: "Netlify Build Hook",
    formType: "netlify",
    optional: true,
    listed: ["update"],
  },
  owner: {
    type: Array,
    label: "User",
    listed: false,
    optional: true,
  },
  "owner.$": {
    type: String,
    listed: false,
  },
  secret: {
    type: String,
    listed: "update",
    group: "Projektdaten",
    autoValue: function () {
      if (this.isInsert) {
        console.log({ secret: generateId() });
        return generateId();
      }
    },
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  updatedAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return new Date();
      }
    },
  },
  createdAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return { $setOnInsert: new Date() };
      } else {
        this.unset(); // Prevent user from supplying their own value
      }
    },
  },
});

if (typeof Mongo !== "undefined") {
  Projects = new Mongo.Collection("projects");
  Projects.attachSchema(Schema);
}

export default Schema;

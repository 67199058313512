import gql from "graphql-tag";
import { useQuery, useApolloClient, useMutation } from "@apollo/client";
export { useQuery, useApolloClient, useMutation };

export const GET_ACCESS = gql`
  mutation getAccess($email: String!, $password: String!) {
    getAccess(email: $email, password: $password)
  }
`;

export const HANDLE_DOC = gql`
  mutation handleDoc(
    $type: String!
    $_id: ID
    $content: Object!
    $mode: String!
  ) {
    handleDoc(type: $type, _id: $_id, content: $content, mode: $mode)
  }
`;

export const USER_QUERY = gql`
  query user {
    user {
      _id
      profile
      roles
      userProjects {
        _id
        name
        projectColor
        contentTypes {
          _id
          name
        }
        logo
        logoFile {
          name
          type
          data
          thumbnailUrl
        }
      }
    }
  }
`;

export const GET_ENTRIES = gql`
  query contententrys($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    contententrys(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "ContentEntry", filter: ["filter"]) {
      results {
        _id
        contentTypeId
        published
        content
        updatedAt
      }
      pages
    }
  }
`;

export const GET_USERS = gql`
  query users($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "Users", filter: ["filter"]) {
      results {
        _id
        name
        emails
        profile
        roles
      }
      pages
    }
  }
`;

export const GET_FILES = gql`
  query files($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    files(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "Files", filter: ["filter"]) {
      results {
        _id
        name
        fileId
        filePath
        url
        thumbnailUrl
        description
        blurhash
        type
        size
        width
        height
        data
        createdAt
      }
      pages
    }
  }
`;

export const GET_TYPE = gql`
  query contenttype($_id: ID) {
    contenttype(_id: $_id) {
      _id
      name
      singleDoc
      schema
      count
      content
    }
  }
`;

export const PROJECT_QUERY = gql`
  query project($_id: ID) {
    project(_id: $_id) {
      _id
      name
      projectColor
      languages
      secret
      contentTypes {
        _id
        name
        singleDoc
        schema
        count
        content
      }
      logo
      logoFile {
        name
        type
        data
        thumbnailUrl
      }
    }
  }
`;

import SimpleSchema from "simpl-schema";

import Options from "./options";
SimpleSchema.extendOptions(Options);

const Schema = new SimpleSchema({
  name: {
    type: String,
    label: "Name",
  },
  pluralName: {
    type: String,
    label: "Name Plural",
    optional: true,
  },
  singleDoc: {
    type: Boolean,
    label: "Single",
    defaultValue: false,
    component: "Toggle",
  },
  projectId: {
    type: String,
    label: "Project Id",
    listed: false,
  },
  schema: {
    type: Object,
    label: "Schema",
    defaultValue: {
      name: {
        type: "String",
        label: "Name",
        key: "name",
        component: "Text",
        preview: true,
        optional: false,
      },
    },
    blackbox: true,
    component: "Schema",
  },
  content: {
    type: Object,
    label: "content",
    blackbox: true,
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  updatedAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return new Date();
      }
    },
  },
  createdAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return { $setOnInsert: new Date() };
      } else {
        this.unset(); // Prevent user from supplying their own value
      }
    },
  },
});

if (typeof Mongo !== "undefined") {
  ContentTypes = new Mongo.Collection("content-types");
  ContentTypes.attachSchema(Schema);
}

export default Schema;

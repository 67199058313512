import * as React from "react";
import { useNavigation } from "@react-navigation/native";
import AsyncStorage from "@react-native-community/async-storage";
import { useApolloClient } from "@apollo/client";
import {
  USER_QUERY,
  PROJECT_QUERY,
  HANDLE_DOC,
  useMutation,
  useQuery,
} from "./api/user";
import { showAlert } from "unikit";

import { CALL_METEOR } from "./api/mutations";

export const AppState = React.createContext({});

export { useNavigation };

export const AppStateProvider = ({ children }) => {
  const client = useApolloClient();
  const { data = {}, loading, refetch } = useQuery(USER_QUERY);
  const { user = null } = data;

  const [handleDoc, handleDocState] = useMutation(HANDLE_DOC);
  const [callMeteor, callMeteorState] = useMutation(CALL_METEOR);

  const [state, setState] = React.useState({ projectId: null });

  const projectState = useQuery(PROJECT_QUERY, {
    variables: {
      _id: state.projectId,
    },
    skip: !state.projectId,
  });

  const { project } = projectState?.data || {};

  console.log({ project });

  const logout = () => {
    AsyncStorage.removeItem("token");
    client
      .resetStore()
      .then(() => {
        console.log("logout and reset");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const call = ({ name, options, onSuccess }) => {
    callMeteor({
      variables: {
        name: name,
        options,
      },
    })
      .then((result) => {
        onSuccess?.({ result });
      })
      .catch((e) => {
        showAlert({
          type: "error",
          message: e.message,
        });
      });
  };

  const inRole = ({ roles, userRoles, key = "__global_roles__" }) => {
    if (!userRoles) userRoles = user?.roles || {};
    const rolesByKey = userRoles[key];
    if (rolesByKey?.length > 0) {
      return rolesByKey?.filter((r) => roles.includes(r)).length > 0;
    } else {
      return false;
    }
  };

  return (
    <AppState.Provider
      value={{
        ...state,
        set: (obj) => {
          setState((s) => ({ ...s, ...obj }));
        },
        user,
        userLoading: loading,
        logout,
        refetchUser: refetch,
        handleDoc,
        handleDocState,
        callMeteor,
        callMeteorState,
        call,
        callState: callMeteorState,
        project,
        projectLoading: projectState.loading,
        refetchProject: projectState.refetch,
        inRole,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppState = () => {
  const appState = React.useContext(AppState);
  return appState;
};

import React, { forwardRef } from "react";
import { Input, useTheme, Flex, getValue, setValue, Text } from "unikit";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import List from "./List";
import MediaList from "./MediaList";
import LangSelectInput from "./LangSelectInput";
import Schema from "./Schema";
import Components from "./Components";
import RichText from "./RichText";

import { useMutation, gql } from "../api/mutations";
import { useAppState } from "../AppContext";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

const Comps = {
  Toggle: "switch",
  Color: "color",
  Number: "number",
  Textarea: "textarea",
  Select: "select",
  Date: "date",
  Tabs: "tabs",
  Relation: List,
  List: List,
  Media: MediaList,
  JSON: JsonEditor,
  Lang: LangSelectInput,
  Schema,
  Components,
  RichText,
};

function slugify(text, separator = "-") {
  text = text.toString().toLowerCase().trim();

  const sets = [
    { to: "a", from: "[ÀÁÂÃÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]" },
    { to: "ae", from: "[Ä]" },
    { to: "c", from: "[ÇĆĈČ]" },
    { to: "d", from: "[ÐĎĐÞ]" },
    { to: "e", from: "[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]" },
    { to: "g", from: "[ĜĞĢǴ]" },
    { to: "h", from: "[ĤḦ]" },
    { to: "i", from: "[ÌÍÎÏĨĪĮİỈỊ]" },
    { to: "j", from: "[Ĵ]" },
    { to: "ij", from: "[Ĳ]" },
    { to: "k", from: "[Ķ]" },
    { to: "l", from: "[ĹĻĽŁ]" },
    { to: "m", from: "[Ḿ]" },
    { to: "n", from: "[ÑŃŅŇ]" },
    { to: "o", from: "[ÒÓÔÕØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]" },
    { to: "oe", from: "[ŒÖ]" },
    { to: "p", from: "[ṕ]" },
    { to: "r", from: "[ŔŖŘ]" },
    { to: "s", from: "[ŚŜŞŠ]" },
    { to: "ss", from: "[ß]" },
    { to: "t", from: "[ŢŤ]" },
    { to: "u", from: "[ÙÚÛŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]" },
    { to: "ue", from: "[Ü]" },
    { to: "w", from: "[ẂŴẀẄ]" },
    { to: "x", from: "[ẍ]" },
    { to: "y", from: "[ÝŶŸỲỴỶỸ]" },
    { to: "z", from: "[ŹŻŽ]" },
    { to: "-", from: "[·/_,:;']" },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, "gi"), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text

  if (typeof separator !== "undefined" && separator !== "-") {
    text = text.replace(/-/g, separator);
  }

  return text;
}

export default forwardRef(
  (
    {
      schema,
      defaultDoc = {},
      variables = {},
      lang = "de",
      onSuccess,
      json = false,
      context = {},
    },
    ref
  ) => {
    const { project } = useAppState();
    const theme = useTheme();
    const [doc, setDoc] = React.useState(defaultDoc);
    const [jsonKey, setJsonKey] = React.useState(0);
    console.log({ schema, defaultDoc });

    const [handleDoc] = useMutation(gql`mutation handleDoc(
      $type: String!
      $_id: ID
      $content: Object!
      $mode: String!
      $projectId: String!
      $contentTypeId: String!
    ) {
      handleDoc(type: $type, _id: $_id, content: $content, mode: $mode, projectId: $projectId, contentTypeId: $contentTypeId) {
        ... on ${variables.type} {
          _id
        }
      }
    }
  `);

    React.useImperativeHandle(ref, () => ({
      getDoc: () => {
        return doc;
      },
      submit: () => {
        const alertKey = theme.alert({
          type: "surface",
          loading: true,
          timeout: false,
          message: "",
        });
        handleDoc({
          variables: {
            content: doc,
            type: variables.type,
            mode: variables.mode,
            _id: variables._id,
            projectId: project._id,
            contentTypeId: variables.contentTypeId,
          },
          refetchQueries: variables.refetchQueries,
        })
          .then((result) => {
            console.log({ result });
            theme.alert({
              key: alertKey,
              loading: false,
              type: "success",
              message: "Gespeichert 😊",
              timeout: 2000,
            });
            if (onSuccess) onSuccess({ item: result?.data?.handleDoc });
          })
          .catch((error) => {
            theme.alert({
              key: alertKey,
              loading: false,
              type: "error",
              message: error.message,
              timeout: 2000,
            });
          });
      },
    }));

    const onChange = ({ value, key }) => {
      setJsonKey((k) => k + 1);

      setDoc((d) => {
        const foundSligify = Object.values(schema).find(
          (i) => i.slugify === key
        );
        console.log({ foundSligify });
        if (
          !project?.languages ||
          (project?.languages && lang === project?.languages[0])
        ) {
          return {
            ...d,
            [key]: value,
            ...(foundSligify ? { [foundSligify.key]: slugify(value) } : {}),
          };
        } else {
          return {
            ...d,
            translations: {
              ...(d?.translations || {}),
              [lang]: {
                ...(d?.translations && d?.translations[lang]
                  ? d?.translations[lang]
                  : {}),
                [key]: value,
                ...(foundSligify ? { [foundSligify.key]: slugify(value) } : {}),
              },
            },
          };
        }
      });
    };

    React.useEffect(() => {
      console.log({ doc });
    }, [doc]);
    return (
      <Flex w="100%" p={15}>
        {Object.keys(schema).map((key, index) => {
          const {
            component,
            defaultValue,
            type,
            label,
            optional,
            translated,
            listed,
            options,
            ...rest
          } = schema[key];
          const isListed = isFunction(listed) ? listed({ doc }) : listed;
          if (isListed === false) return null;
          if (project && !translated && lang !== project?.languages[0])
            return null;
          const Comp = Comps[component] || "text";
          console.log({ options });
          const value = getValue(
            doc,
            lang !== project?.languages[0] ? `translations.${lang}.${key}` : key
          );
          if (typeof Comp !== "string") {
            return (
              <Input label={label} mb={10} clean>
                <Comp
                  {...rest}
                  key={key}
                  field={key}
                  type={type}
                  placeholder={label}
                  label={label}
                  defaultValue={defaultValue}
                  value={value}
                  onChange={(value) => onChange({ value, key })}
                  options={
                    isFunction(options) ? options({ doc, context }) : options
                  }
                  doc={doc}
                  context={context}
                  isInput
                />
              </Input>
            );
          }
          return (
            <Input
              {...rest}
              key={key}
              field={key}
              type={Comp}
              label={label}
              placeholder={label}
              defaultValue={defaultValue}
              required={!optional}
              value={value}
              onChange={(value) => onChange({ value, key })}
              options={
                isFunction(options) ? options({ doc, context }) : options
              }
              mb={10}
              clean
            />
          );
        })}
        {json ? (
          <>
            <Text mt={20} mb={10}>
              JSON
            </Text>
            <JsonEditor
              value={doc}
              key={`json-${jsonKey}`}
              onChange={(v) => {
                setDoc((d) => ({ ...v, _id: d._id }));
              }}
            />
          </>
        ) : null}
      </Flex>
    );
  }
);

import React, { useState } from "react";
import { Tabs, useTheme, Flex } from "unikit";
import { useApolloClient } from "@apollo/client";

import { GET_USERS } from "../api/user";
import {
  Page,
  ListItem,
  ListViewQuery,
  Search,
  renderAction,
} from "components";
import { useAppState } from "../AppContext";

export default function Screen({ navigation: { navigate, push } }) {
  const client = useApolloClient();
  const theme = useTheme();
  const { project, call, inRole } = useAppState();
  const [searchData, setSearchData] = useState(null);
  const projectId = project?._id;
  const [type, setType] = useState(
    inRole({ roles: ["Admin"], key: projectId }) ? "Admin" : "Author"
  );

  var schema = {
    name: {
      label: "Name",
    },
    "emails.0.address": {
      label: "E-Mail",
    },
  };

  const roleOptions = [
    ...(inRole({ roles: ["Admin"], key: projectId }) ? ["Admin"] : []),
    "Author",
    "User",
  ];

  schema[`roles.${projectId}`] = {
    component: "Select",
    label: "Role",
    options: roleOptions,
    multi: true,
    inline: true,
    picker: "switch",
  };

  const createNew = () => {
    navigate("Entry", {
      title: "User hinzufügen",
      mode: "insert",
      type: "User",
      typeId: "User",
      schema: schema,
      refetchQueries: ["users"],
      onSubmit: ({ doc, navigation }) => {
        console.log({ doc });
        call({
          name: "insertUser",
          options: {
            name: doc.name,
            email: doc["emails.0.address"],
            roles: doc[`roles.${projectId}`],
            projectId: project._id,
          },
          onSuccess: () => {
            navigation?.goBack();
            theme.alert({
              type: "success",
              message: "User gespeichert",
            });
            client.refetchQueries({
              include: [`users`],
            });
          },
        });
      },
    });
  };

  return (
    <Page
      title="User"
      rightAction={
        <Flex alignItems="center" row>
          {renderAction({
            icon: "plus",
            bg: "primary",
            color: "#FFF",
            onPress: () => {
              createNew();
            },
          })}
        </Flex>
      }
      navbarProps={{
        footerComponent: (
          <Flex w="100%" borderTopWidth={2} borderColor="primary:setAlpha:0.1">
            <Tabs
              indicatorSize={3}
              activeColor="primary"
              color="text"
              options={roleOptions}
              onChange={(value) => setType(value)}
            />
          </Flex>
        ),
      }}
    >
      <Search
        query={GET_USERS}
        searchKey={"emails.address"}
        onChange={({ data }) => setSearchData(data["users"])}
        filter={{
          [`roles.${projectId}`]: { $exists: true },
          [`roles.$${projectId}`]: { $in: [type] },
        }}
      />
      <ListViewQuery
        query={GET_USERS}
        searchData={searchData}
        filter={{
          [`roles.${projectId}`]: { $exists: true },
          [`roles.${projectId}`]: { $in: [type] },
        }}
        sort={{ "emails[0].address": 1 }}
        gap={10}
        name="users"
        infinite={false}
        renderItem={({ item, index, limit }) => {
          console.log({ user: item });
          return (
            <ListItem
              title={item.emails[0].address}
              onPress={() => {
                push("Entry", {
                  title: item.emails[0].address,
                  type: "User",
                  mode: "update",
                  _id: item._id,
                  defaultDoc: item,
                  schema,
                  onSubmit: ({ doc, navigation }) => {
                    call({
                      name: "updateUser",
                      options: {
                        _id: item._id,
                        name: doc.name,
                        email: doc["emails.0.address"],
                        roles: doc[`roles.${projectId}`],
                        projectId: project._id,
                      },
                      onSuccess: () => {
                        navigation?.goBack();
                        theme.alert({
                          type: "success",
                          message: "User gespeichert",
                        });
                        client.refetchQueries({
                          include: [`users`],
                        });
                      },
                    });
                  },
                });
              }}
            />
          );
        }}
      />
    </Page>
  );
}

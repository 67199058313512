import React, { useEffect } from "react";
import { Gyroscope } from "expo-sensors";
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  withTiming,
} from "react-native-reanimated";
import { useInterval, isWeb } from "unikit";
const GyroscopeAnimation = ({ children }) => {
  const gyroValue = useSharedValue({ x: 0, y: 0, z: 0 });
  const prev = useSharedValue({ x: 0, y: 0 });
  const scale = useSharedValue(1);

  const setRandomScale = () => {
    var precision = 100;
    var randomnum =
      Math.floor(
        Math.random() * (2 * precision - 1 * precision) + 1 * precision
      ) /
      (1 * precision);
    scale.value = randomnum;
  };

  useInterval(() => {
    setRandomScale();
  }, 3000);

  useEffect(async () => {
    const status = Gyroscope.isAvailableAsync();
    if (isWeb || !status) return null;
    Gyroscope.setUpdateInterval(16);
    let Listener = Gyroscope.addListener(({ x, y, z }) => {
      gyroValue.value = { x, y, z };
    });
    setRandomScale();
    return () => {
      try {
        Listener.remove();
      } catch (err) {
        console.log("Gyroscope Animation unsubscribe Error: ", err);
      }
    };
  }, []);

  const derivedTranslations = useDerivedValue(() => {
    "worklet";
    const MAX_X = 540;
    const MAX_Y = 540;

    let newX = prev.value.x + gyroValue.value.y * -1;
    let newY = prev.value.y + gyroValue.value.x * -1;

    if (Math.abs(newX) >= MAX_X) {
      newX = prev.value.x;
    }
    if (Math.abs(newY) >= MAX_Y) {
      newY = prev.value.y;
    }
    prev.value = {
      x: newX,
      y: newY,
    };
    return {
      x: newX,
      y: newY,
    };
  }, [gyroValue.value]);

  const AnimatedStyles = {
    motion: useAnimatedStyle(() => {
      return {
        transform: [
          {
            translateX: withSpring(derivedTranslations.value.x),
          },
          {
            translateY: withSpring(derivedTranslations.value.y),
          },
          { scale: withTiming(scale.value, { duration: 3000 }) },
        ],
      };
    }),
  };
  return (
    <Animated.View
      style={[
        {
          width: "150%",
          height: "150%",
          position: "absolute",
          left: "-25%",
          top: "-25%",
          right: 0,
          bottom: 0,
          zIndex: 0,
        },
        AnimatedStyles.motion,
      ]}
    >
      {children}
    </Animated.View>
  );
};

export default GyroscopeAnimation;

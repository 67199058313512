import React, { Fragment, useState } from "react";
import { FlatList, View } from "react-native";
import { Progress, Flex, useLayout, useTheme, getValue } from "unikit";

import NoContent from "./NoContent";

function chunkArray(array = [], size) {
  const newArray = [...array];
  for (var i = 0; i < newArray.length; i++) {
    const num = newArray.length / size;
    if (num % 1 !== 0) {
      newArray.push({ spacer: true });
    }
  }
  return newArray;
}

Array.prototype.groupBy = function (prop, groupByFormat, hideEmptyGroup) {
  return this.reduce(function (groups, item) {
    const val = groupByFormat
      ? groupByFormat({ key: prop, value: getValue(item, prop) })
      : getValue(item, prop);
    if ((hideEmptyGroup && val) || !hideEmptyGroup) {
      groups[val] = groups[val] || [];
      groups[val].push(item);
    }
    return groups;
  }, {});
};

export default ({
  horizontal = false,
  gap = 10,
  grid = false,
  itemDimension = 300,
  contentContainerStyle = {},
  loadMore = false,
  moreToFetch,
  data = [],
  min,
  renderItem,
  style,
  loading,
  fetchMore,
  limit,
  offset,
  groupBy,
  groupByFormat,
  sectionAction,
  renderFooter,
  renderHeader,
  lastItem,
  hideEmptyGroup,
  noContentKey,
  ...rest
}) => {
  const theme = useTheme();
  const { onLayout, width = 0 } = useLayout();
  data = data ? [...data, ...(lastItem ? [{ last: true }] : [])] : [];
  const ListProps = grid
    ? {
        data: chunkArray(data, Math.max(1, Math.floor(width / itemDimension))),
        onLayout,
        extraData: width,
        numColumns: Math.max(1, Math.floor(width / itemDimension)),
        contentContainerStyle,
        style: {
          flex: 1,
          paddingTop: theme.globals.gap,
          paddingHorizontal: theme.globals.gap,
          opacity: grid && width === 0 ? 0 : 1,
          ...style,
        },
      }
    : {
        data: data,
        contentContainerStyle: horizontal ? {} : contentContainerStyle,
        style: horizontal
          ? { paddingHorizontal: theme.globals.gap, ...style }
          : {
              ...style,
            },
      };

  if (groupBy) {
    //data = data.groupBy(groupBy, groupByFormat, hideEmptyGroup);
  }

  //console.log("ListView", data);

  // if (groupBy) {
  //   return (
  //     <SectionList
  //       renderItem={renderItem}
  //       renderSectionHeader={({ section }) => (
  //         <View
  //           style={{
  //             backgroundColor: "rgba(255,255,255,0.9)",
  //             padding: 5,
  //             borderBottomColor: "rgba(0,0,0,0.05)",
  //             borderBottomWidth: 1
  //           }}
  //         >
  //           <Text>{section.title}</Text>
  //         </View>
  //       )}
  //       sections={Object.keys(data).map(key => {
  //         return { title: key, data: data[key] };
  //       })}
  //       onEndReached={() => {
  //         if (fetchMore && infinite === true) {
  //           fetchMore();
  //         }
  //       }}
  //       ListFooterComponent={renderFooter || null}
  //       ListHeaderComponent={renderHeader || null}
  //       keyExtractor={(item, index) => `list-item-${index}`}
  //       stickySectionHeadersEnabled
  //       ListEmptyComponent={
  //         loading || data.length > 0 ? (
  //           <Flex align="center" content="center" w="100%" h={400}>
  //             <Progress
  //               circleColor="primary"
  //               size={60}
  //               trackWidth={5}
  //               circleWidth={3}
  //               loading
  //             />
  //           </Flex>
  //         ) : (
  //           <View
  //             style={{
  //               height: 300,
  //               flex: 1,
  //               alignItems: "center",
  //               justifyContent: "center"
  //             }}
  //           >
  //             <Text style={{ marginTop: 20 }}>noContent</Text>
  //           </View>
  //         )
  //       }
  //     />
  //   );
  // }

  return (
    <FlatList
      data={data}
      key={`list-${ListProps.numColumns || "nogrid"}`}
      keyExtractor={(item, index) => `list-item-${index}`}
      style={{
        ...style,
        flexGrow: horizontal ? 0 : 1,
        flexBasis: "auto",
      }}
      renderItem={({ item, index }) => {
        if (item.spacer) {
          return <View style={{ flex: 1 }} />;
        }
        return (
          <View
            style={{
              flex: 1,
              width: horizontal ? itemDimension : "100%",
              height: "auto",
              paddingRight: grid
                ? (index + 1) % ListProps.numColumns === 0
                  ? 0
                  : theme.globals.gap
                : horizontal
                ? theme.globals.gap
                : 0,
              paddingBottom: theme.globals.gap / 2,
            }}
          >
            {item.last ? lastItem : renderItem({ item, index, limit })}
          </View>
        );
      }} // item index
      ListFooterComponent={<Fragment>{renderFooter || null}</Fragment>}
      ListHeaderComponent={renderHeader || null}
      horizontal={horizontal}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      ListEmptyComponent={
        loading || data.length > 0 ? (
          <Flex
            flexCenter
            style={{
              flex: 1,
              minHeight: "calc(100vh - 120px)",
            }}
          >
            <Progress trackWidth={3} circleWidth={2} loading />
          </Flex>
        ) : (
          <NoContent title="Keine Inhalte gefunden" />
        )
      }
      {...ListProps}
      {...rest}
    />
  );
};

import React, { useState, useEffect } from "react";
import { Flex, TextInput, styled, useDebounce, Icon } from "unikit";

import { useQuery } from "../api/user";

const Search = styled(Flex)({
  width: "100%",
  height: 50,
});

export default ({
  query,
  name,
  searchKey = "name",
  onChange,
  projectId,
  contentTypeId,
  filter = {},
  ...rest
}) => {
  const [searchString, setSearchString] = useState("");

  const debouncedSearchTerm = useDebounce(searchString, 500);

  const { data = {}, loading, error } = useQuery(query, {
    variables: {
      filter: {
        [searchKey]: { $regex: `.*${debouncedSearchTerm}.*`, $options: "i" },
        ...(projectId ? { projectId: projectId } : {}),
        ...(contentTypeId ? { contentTypeId: contentTypeId } : {}),
        ...filter,
      },
    },
    skip: searchString < 3,
  });

  useEffect(() => {
    console.log({ data, loading, error });
    onChange({ data, loading, error });
  }, [data, loading, error]);

  return (
    <Search align="center" bg="surface:setAlpha:0.4" shadow={30} pl={17} row>
      <Icon
        name="search"
        size={26}
        color="primary"
        style={{
          position: "absolute",
          left: 10,
          top: 10,
          zIndex: 10,
        }}
      />
      <TextInput
        value={searchString}
        placeholder={"Suchen..."}
        style={{
          height: 50,
          paddingLeft: 16,
          backgroundColor: "transparent",
        }}
        onChange={(text) => setSearchString(text)}
        autoFocus
      />
    </Search>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/client";
import { styled, Button, Flex, Group, Icon } from "unikit";

import ListView from "./ListView";

const Label = styled.Text(({ color, size }) => ({
  color: color,
  fontSize: size || "label",
  marginBottom: 10,
  paddingLeft: 15,
}));

export default ({
  label,
  title,
  query,
  filter = {},
  searchData,
  sort = {},
  name,
  limit = 20,
  addTitle = "",
  onAdd,
  typename,
  horizontal = false,
  onLoad,
  action = null,
  skip = false,
  count = 0,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [refreshing, setRefresh] = useState(false);
  const listRef = useRef(null);
  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables: {
      filter: filter,
      sort: sort,
      page: page,
      limit: limit || undefined,
    },
    skip: skip,
  });

  console.log({ data });

  useEffect(() => {
    if (loading === false && data && data[name] && onLoad) {
      onLoad({ name, data: data[name] });
    }
  }, [loading, data]);

  useEffect(() => {
    refetch();
  }, [page]);

  const listData = searchData
    ? searchData
    : data && data[name]
    ? data[name].results
    : [];

  return (
    <ListView
      ref={listRef}
      data={listData}
      loading={loading}
      limit={page * limit}
      refreshing={refreshing}
      style={horizontal ? { padding: 7.5 } : {}}
      horizontal={horizontal}
      onRefresh={() => {
        setRefresh(true);
        refetch()
          .then(() => {
            setRefresh(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }}
      moreToFetch={listData.length >= (page + 1) * limit}
      fetchMore={() => {
        console.log("fetchMore", data[name].length, (page + 1) * limit);
        if (data[name] && data[name].length >= (page + 1) * limit) {
          let newPage = page + 1;

          setPage(newPage);
          fetchMore({
            variables: {
              filter: filter,
              sort: sort,
              page: newPage,
              limit: limit || undefined,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult || !fetchMoreResult[name]) return prev;
              return Object.assign({}, prev, {
                [name]: [...prev[name], ...fetchMoreResult[name]],
              });
            },
          });
        }
      }}
      renderFooter={
        data && data[name] && data[name].pages > 0 && !searchData ? (
          <Flex
            w="100%"
            style={{ maxWidth: 500 }}
            p={10}
            mb={10}
            bg="surface"
            justifyContent="center"
            borderRadius={7}
            row
            shadow={5}
          >
            <Button
              mr={10}
              onPress={() => {
                if (page > 0) setPage(page - 1);
              }}
              light
              size={34}
            >
              <Icon name="chevron-left" size={22} />
            </Button>
            <Group w={350}>
              {Array.from(Array(data[name].pages).keys()).map((p, index) => {
                if (
                  index === 0 ||
                  (index < page + 3 && index > page - 3) ||
                  index === data[name].pages - 1
                ) {
                  return (
                    <Button
                      onPress={() => {
                        setPage(p);
                      }}
                      light={p !== page}
                      size={34}
                    >
                      {p.toString()}
                    </Button>
                  );
                }
              })}
            </Group>
            <Button
              ml={10}
              onPress={() => {
                setPage((page + 1) % data[name].pages);
              }}
              light
              size={34}
            >
              <Icon name="chevron-right" size={22} />
            </Button>
          </Flex>
        ) : null
      }
      {...rest}
    />
  );
};

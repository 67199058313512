import {
  NativeScrollEvent,
  RefreshControl,
  RefreshControlProps,
  ScrollView,
  ScrollViewProps,
  StyleProp,
  View,
} from "react-native";
import React, { ReactElement, useState } from "react";
import Animated from "react-native-reanimated";

import { withThemeProps } from "../../style";
import { useLayout } from "../../hooks";

const AnimatedList = Animated.createAnimatedComponent(ScrollView);

interface Props<T>
  extends Omit<ScrollViewProps, "refreshControl" | "onScroll"> {
  keyPrefix?: string;
  loading?: boolean;
  refreshing?: RefreshControlProps["refreshing"];
  onRefresh?: RefreshControlProps["onRefresh"];
  onEndReached?: () => void;
  onEndReachedThreshold?: number;
  style?: StyleProp<ScrollViewProps>;
  data: T[];
  renderItem: ({ item: T, i: number }) => ReactElement;
  LoadingView?: React.ComponentType<any> | React.ReactElement | null;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  numColumns?: number;
}

const isCloseToBottom = (
  { layoutMeasurement, contentOffset, contentSize },
  onEndReachedThreshold
) => {
  const paddingToBottom = contentSize.height * onEndReachedThreshold;

  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

function MasonryList({
  keyPrefix,
  refreshing,
  data,
  ListHeaderComponent,
  ListEmptyComponent,
  ListFooterComponent,
  renderItem,
  onEndReachedThreshold,
  onEndReached,
  onRefresh,
  loading,
  LoadingView,
  numColumns,
  gap = 5,
  children,
  outerGap = false,
  style,
  min,
  ...rest
}: Props) {
  const { width, onLayout } = useLayout();
  let isChildData = false;
  if (!data && children) {
    data = React.Children.toArray(children);
    isChildData = true;
  }
  if (!numColumns && min && width) {
    numColumns = Math.max(1, Math.floor(width / min));
  }
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <AnimatedList
      onLayout={onLayout}
      style={[{ alignSelf: "stretch" }, style]}
      removeClippedSubviews={true}
      refreshControl={
        <RefreshControl
          refreshing={!!(refreshing && isRefreshing)}
          onRefresh={() => {
            setIsRefreshing(true);
            onRefresh?.();
            setIsRefreshing(false);
          }}
        />
      }
      scrollEventThrottle={16}
      onScroll={({ nativeEvent }: { nativeEvent: NativeScrollEvent }) => {
        if (isCloseToBottom(nativeEvent, onEndReachedThreshold || 0.1))
          onEndReached?.();
      }}
      {...rest}
    >
      {ListHeaderComponent}
      {data.length === 0 && ListEmptyComponent ? (
        React.isValidElement(ListEmptyComponent) ? (
          ListEmptyComponent
        ) : (
          <ListEmptyComponent />
        )
      ) : (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            paddingLeft: outerGap ? gap / 2 : 0,
          }}
        >
          {numColumns
            ? Array.from(Array(numColumns), (_, num) => {
                return (
                  <View
                    key={`${keyPrefix}-${num.toString()}`}
                    style={{
                      flex: 1 / numColumns,
                      paddingRight: num === numColumns - 1 ? 0 : gap,
                    }}
                  >
                    {data
                      .map((el, i) => {
                        if (i % numColumns === num) {
                          if (isChildData) {
                            return el;
                          } else {
                            return renderItem({ item: el, i });
                          }
                        }

                        return null;
                      })
                      .filter((e) => !!e)}
                  </View>
                );
              })
            : null}
        </View>
      )}
      {loading && LoadingView}
      {ListFooterComponent}
    </AnimatedList>
  );
}

export default withThemeProps(MasonryList, "MasonryList");

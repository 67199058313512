import * as React from "react";
import { Button, Flex, useTheme, Tabs } from "unikit";

import { Form, Page, NoContent, renderAction } from "components";
import { useAppState } from "../AppContext";
import { ContentTypeSchema } from "../contist-server/lib/schemas";

const buildString = ({ schema }) => {
  let string = "";
  Object.keys(schema || {}).map((key, index) => {
    const { component } = schema[key];
    const adder = index > 0 ? "\t\t\t" : "";
    if (component === "Media") {
      string += `${adder}${key} {\n${adder}\t_id\n${adder}}\n`;
    } else if (component === "Relation") {
      string += `${adder}${key} {\n${adder}\t_id\n${adder}}\n`;
    } else {
      string += `${adder}${key}\n`;
    }
  });
  return string;
};

export default function Screen({ navigation, route }) {
  const theme = useTheme();
  const { project, refetchProject, callMeteor } = useAppState();
  const [type, setType] = React.useState(null);
  const formRef = React.useRef(null);

  const { contentTypes = [] } = project || {};

  React.useEffect(() => {
    if (contentTypes.length > 0 && type === null) {
      setType(contentTypes[0]._id);
    }
  }, [contentTypes]);

  const contentType = contentTypes.find((c) => c._id === type);

  const createNew = () => {
    navigation.navigate("Entry", {
      title: "Content-Type erstellen",
      mode: "insert",
      schema: ContentTypeSchema?._schema,
      onSubmit: ({ doc, navigation }) => {
        callMeteor({
          variables: {
            name: "createContentType",
            options: { ...doc, projectId: project._id },
          },
        })
          .then((result) => {
            console.log({ result });
            theme.alert({ type: "success", message: "Yeah" });
            navigation.goBack();
            refetchProject();
          })
          .catch((error) => {
            theme.alert({ type: "error", message: error.message });
          });
      },
    });
  };

  return (
    <Page
      title="Content Types"
      rightAction={
        <Flex alignItems="center" row>
          {contentType ? (
            <>
              {renderAction({
                icon: "zap",
                text: "Playground",
                onPress: () => {
                  const baseUrl = __DEV__
                    ? `http://localhost:4000/graphql/${project._id}`
                    : `https://api.contist.io/graphql/${project._id}`;
                  const tabsData = [
                    {
                      // tab url
                      endpoint: baseUrl,
                      // tab name
                      name: contentType.name,
                      // tab variables, NOTE: variables are unique in that they must be passed to the VariableEditor as a String, hence JSON.stringify
                      variables: JSON.stringify({
                        filter: {},
                        sort: {},
                        page: 0,
                      }),
                      // tab headers
                      headers: { secret: project.secret },
                      // tab query
                      query: contentType.singleDoc
                        ? `query ${contentType.name} {
    ${contentType.name} {
      ${buildString({ schema: contentType.schema })}
    }
  }`
                        : `query ${
                            contentType.name
                          }s($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    ${
      contentType.name
    }s(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      _id
      ${buildString({ schema: contentType.schema })}
    }
  }`,
                    },
                  ];
                  const tabsQueryParam = new URLSearchParams({
                    tabs: JSON.stringify(tabsData),
                  }).toString();

                  window.open(`${baseUrl}?${tabsQueryParam}`, "_blank");
                },
              })}

              {renderAction({ icon: "plus", onPress: createNew, ml: 10 })}
              {renderAction({
                ml: 10,
                icon: "check",
                bg: "primary",
                color: "#FFF",
                onPress: () => {
                  const doc = formRef.current?.getDoc();
                  callMeteor({
                    variables: {
                      name: "updateContentType",
                      options: {
                        ...doc,
                        projectId: project._id,
                        contentTypeId: contentType._id,
                      },
                    },
                  })
                    .then((result) => {
                      console.log({ result });
                      theme.alert({ type: "success", message: "Yeah" });
                      refetchProject();
                    })
                    .catch((error) => {
                      theme.alert({ type: "error", message: error.message });
                    });
                },
              })}
            </>
          ) : null}
        </Flex>
      }
      navbarProps={{
        footerComponent: (
          <Flex w="100%" borderTopWidth={2} borderColor="primary:setAlpha:0.1">
            {contentTypes.length > 0 && !route?.params?.typeId ? (
              <Tabs
                indicatorSize={3}
                activeColor="primary"
                color="text"
                mt={-2}
                bg="transparent"
                options={contentTypes.map((item) => ({
                  value: item._id,
                  label: `${item.name}(${item.count})`,
                }))}
                onChange={(value) => setType(value)}
              />
            ) : null}
          </Flex>
        ),
      }}
    >
      <Flex key={contentType?._id}>
        {contentType ? (
          <>
            <Form
              ref={formRef}
              schema={ContentTypeSchema?._schema}
              defaultDoc={contentType}
              context={{ contentTypes }}
            />
          </>
        ) : (
          <NoContent
            title="No ContentTypes created"
            desc="Start by creating a ContentType that fit your needs."
            action="Create new Type"
            onAction={() => {
              createNew();
            }}
          />
        )}
      </Flex>
    </Page>
  );
}

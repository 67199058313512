import React from "react";
import { StyleSheet, View } from "react-native";

import Graph from "./Graph";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 250,
    backgroundColor: "white",
    justifyContent: "space-between",
  },
});

const Rainbow = () => {
  return (
    <View style={styles.container}>
      <Graph />
    </View>
  );
};

export default Rainbow;

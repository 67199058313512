import SimpleSchema from "simpl-schema";

import Options from "./options";
SimpleSchema.extendOptions(Options);

const Schema = new SimpleSchema({
  projectId: {
    type: String,
    label: "Project _id"
  },
  contentTypeId: {
    type: String,
    label: "ContentType _id"
  },
  published: {
    type: Boolean,
    label: "Veröffentlicht",
    defaultValue: true,
    component: "Toggle"
  },
  content: {
    type: Object,
    label: "Content",
    optional: true,
    blackbox: true
  },
  createdBy: {
    type: String,
    listed: false
  },
  updatedAt: {
    type: Date,
    autoValue: function() {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return new Date();
      }
    }
  },
  createdAt: {
    type: Date,
    autoValue: function() {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return { $setOnInsert: new Date() };
      } else {
        this.unset(); // Prevent user from supplying their own value
      }
    }
  }
});

if (typeof Mongo !== "undefined") {
  ContentEntries = new Mongo.Collection("content-entries");
  ContentEntries.attachSchema(Schema);
}

export default Schema;

import * as React from "react";
import { Button, Flex } from "unikit";

import { useQuery, GET_TYPE } from "../api/user";
import { Form, Page, LangSelect, renderAction } from "components";
import { useAppState } from "../AppContext";

export default function Screen({ navigation, route }) {
  const { project, inRole } = useAppState();
  const [lang, setLang] = React.useState(project?.languages[0] || "de");
  const formRef = React.useRef(null);

  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_TYPE, {
    variables: {
      _id: route?.params?.typeId,
    },
    skip: !route?.params?.typeId || route?.params?.typeId === "User",
  });

  const { contenttype = {} } = data;

  const schema = contenttype?.schema
    ? {
        ...(route?.params?.mode !== "insert"
          ? { _id: { label: "_id", editable: false, key: "_id" } }
          : {}),
        ...(contenttype.schema || {}),
      }
    : undefined;

  return (
    <Page
      title={route?.params?.title}
      goBack={navigation?.goBack}
      rightAction={
        <Flex alignItems="center" row>
          {project?.languages?.length > 1 ? (
            <LangSelect
              value={lang}
              languages={project?.languages}
              onChange={(l) => setLang(l)}
            />
          ) : null}
          {renderAction({
            icon: "check",
            bg: "primary",
            color: "#FFF",
            onPress: () => {
              if (route?.params?.onSubmit) {
                route?.params?.onSubmit({
                  doc: formRef.current.getDoc(),
                  navigation,
                });
              } else {
                formRef.current.submit();
              }
            },
          })}
        </Flex>
      }
      scrollable
    >
      <Form
        ref={formRef}
        lang={lang}
        schema={route?.params?.schema || schema || {}}
        defaultDoc={route?.params?.defaultDoc || {}}
        onSuccess={route?.params?.onSuccess}
        context={route?.params?.context}
        push={navigation.push}
        json={inRole({ roles: ["Admin"], key: project?._id })}
        variables={{
          type: route?.params?.type,
          mode: route?.params?.mode,
          contentTypeId: route?.params?.typeId,
          _id: route?.params?._id,
          refetchQueries: route?.params?.refetchQueries,
        }}
      />
    </Page>
  );
}

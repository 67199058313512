import SimpleSchema from "simpl-schema";

import Options from "./options";
SimpleSchema.extendOptions(Options);

const Schema = new SimpleSchema({
  name: {
    type: String,
    label: "Name",
  },
  fileId: {
    type: String,
    label: "Name",
    listed: false,
  },
  filePath: {
    type: String,
    label: "Name",
    listed: false,
  },
  url: {
    type: String,
    label: "Name",
    listed: false,
  },
  thumbnailUrl: {
    type: String,
    label: "Name",
    listed: false,
  },
  description: {
    type: String,
    label: "Beschreibung",
    optional: true,
  },
  provider: {
    type: String,
    label: "Provider",
    listed: false,
  },
  type: {
    type: String,
    label: "Type",
    optional: true,
    listed: false,
  },
  fileType: {
    type: String,
    label: "Type",
    optional: true,
    listed: false,
  },
  size: {
    type: Number,
    label: "Größe",
    optional: true,
    listed: false,
  },
  width: {
    type: Number,
    label: "Breite",
    optional: true,
    listed: false,
  },
  height: {
    type: Number,
    label: "Höhe",
    optional: true,
    listed: false,
  },
  blurhash: {
    type: String,
    label: "Blurhash",
    optional: true,
    listed: false,
  },
  orientation: {
    type: Number,
    label: "Orientation",
    optional: true,
    listed: false,
  },
  data: {
    type: Object,
    label: "File",
    blackbox: true,
    component: "Dropzone",
    optional: true,
    listed: false,
  },
  projectId: {
    type: String,
    label: "projectId",
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  updatedAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return new Date();
      }
    },
  },
  createdAt: {
    type: Date,
    listed: false,
    autoValue: function () {
      if (this.isInsert) {
        return new Date();
      } else if (this.isUpsert) {
        return { $setOnInsert: new Date() };
      } else {
        this.unset(); // Prevent user from supplying their own value
      }
    },
  },
});

if (typeof Mongo !== "undefined") {
  Files = new Mongo.Collection("files");
  Files.attachSchema(Schema);
}

export default Schema;

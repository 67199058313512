import * as React from "react";
import { svgPathProperties } from "svg-path-properties";
import { G, Circle, Text, Rect } from "react-native-svg";
import { Animated } from "react-native";

import { useTheme, transformColor } from "../../style";
import { colorAware, getValueByProgress, isNumber } from "../../util";

const ValuePoint = Animated.createAnimatedComponent(Circle);

function getPathCoordinates({ d, width, progress }) {
  const properties = new svgPathProperties(d);
  const pathLength = properties.getTotalLength();
  const _x = progress * width;
  var beginning = _x,
    end = pathLength,
    target,
    pos;
  target = Math.floor((beginning + end) / 2);

  while (true) {
    target = Math.floor((beginning + end) / 2);
    pos = properties.getPointAtLength(target);

    if ((target === end || target === beginning) && pos.x !== _x) {
      break;
    }
    if (pos.x > _x) {
      end = target;
    } else if (pos.x < _x) {
      beginning = target;
    } else {
      break; //position found
    }
  }
  return pos;
}

const ValueDot = ({
  data,
  index,
  d,
  scaleY,
  showDot = true,
  showValue,
  valueFontSize,
  valueFont = "caption",
  width,
  strokeWidth,
  dotSize = 5,
  progress,
  color,
  onChange,
  formatValue,
  value,
  dotValue,
  dotStroke,
}) => {
  const opacity = React.useMemo(() => new Animated.Value(0), []);

  const theme = useTheme();
  const pos = React.useMemo(
    () =>
      getPathCoordinates({
        d,
        width,
        progress,
      }),
    [d, progress, width]
  );

  React.useEffect(() => {
    Animated.spring(opacity, {
      toValue: 1,
      useNativeDriver: true,
    }).start();
  }, []);

  const fontSize = valueFontSize || theme.fonts[valueFont].fontSize;
  const val = value || `${scaleY.invert(pos.y).toFixed(2)}`;
  const formatedValue = formatValue
    ? formatValue(val, data[index], index)
    : val;

  const valueWidth =
    formatedValue.toString().length * fontSize * 0.7 + fontSize * 1.5;
  console.log({ formatedValue, valueWidth });

  React.useEffect(() => {
    if (onChange) {
      const newActive = Math.floor(
        getValueByProgress(0, data.length, progress)
      );
      onChange({
        value: val,
        formatedValue: formatValue ? formatValue(val) : val,
        item: data[newActive],
        index: newActive,
      });
    }
  }, [val]);

  if (isNumber(dotValue)) dotSize = dotSize * 2;

  return (
    <G x={pos.x} y={pos.y} style={{ opacity }}>
      {showDot && (
        <>
          <ValuePoint
            w={dotSize}
            h={dotSize}
            r={dotSize}
            value={dotValue}
            strokeWidth={strokeWidth}
            stroke={
              dotStroke
                ? transformColor({
                    value: dotStroke,
                    theme,
                    themeKey: "colors",
                  })
                : color
            }
            fill={theme.colors.surface}
          />
          <Text
            fontSize={dotSize}
            textAnchor="middle"
            fontFamily={theme.globals.fontFamily}
            fill={"#FFF"}
            y={dotSize / 3}
          >
            {dotValue}
          </Text>
        </>
      )}
      {showValue && (
        <>
          <Rect
            width={valueWidth}
            x={-valueWidth / 2}
            y={-(fontSize * 3) - dotSize / 2}
            height={fontSize * 2}
            fill={color}
            align="center"
            rx={fontSize}
            ry={fontSize}
          />
          <Text
            fontSize={fontSize}
            textAnchor="middle"
            fontFamily={theme.globals.fontFamily}
            y={-((fontSize * 3) / 2) - 1 - dotSize / 2}
            fill={colorAware(color, theme)}
          >
            {formatedValue}
          </Text>
        </>
      )}
    </G>
  );
};

export default ValueDot;

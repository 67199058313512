import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { useTheme, Button, styled, Animate, Flex, Text } from "unikit";

const Menu = styled(Flex)({
  borderRadius: 1,
  position: "absolute",
  top: 55,
  right: 10,
  padding: 15,
  minWidth: 320,
  borderRadius: 7,
  zIndex: 9999
});

export default ({ name, children, size, color, style, items = [] }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  return (
    <View style={[{ position: "relative" }, style]}>
      {/* <Icon name={name} color={color} onPress={() => setOpen(!open)} /> */}
      <TouchableOpacity onPress={() => setOpen(!open)}>
        {children}
      </TouchableOpacity>

      <Animate
        as={Menu}
        isVisible={open}
        bg="surface"
        shadow={50}
        br={5}
        pointerEvents={open ? "all" : "none"}
      >
        {items.map((item, index) => {
          return (
            <TouchableOpacity
              onPress={() => {
                item.onPress();
                setOpen(!open);
              }}
              key={`item-${index}`}
            >
              <Flex h={55} px={10} align="center" row>
                {item.renderLeft ? item.renderLeft : null}
                <Text ml={8}>{item.label}</Text>
              </Flex>
            </TouchableOpacity>
          );
        })}
        <Button
          style={{ marginTop: 10 }}
          onPress={() => setOpen(false)}
          light
          rounded
        >
          Fertig
        </Button>
      </Animate>
    </View>
  );
};

import * as React from "react";
import { Button, Flex, useTheme } from "unikit";

import { Form, Page, renderAction } from "components";
import { useAppState } from "../AppContext";
import { ProjectSchema } from "../contist-server/lib/schemas";

export default function Screen({ navigation, route }) {
  const theme = useTheme();
  const { project, refetchProject, callMeteor } = useAppState();
  const formRef = React.useRef(null);
  return (
    <Page
      title="Settings"
      rightAction={renderAction({
        icon: "check",
        bg: "primary",
        color: "#FFF",
        onPress: () => {
          formRef.current.submit();
        },
      })}
    >
      <Form
        key={`settings-${project?._id}`}
        ref={formRef}
        schema={ProjectSchema._schema}
        lang={project?.languages[0]}
        defaultDoc={{
          name: project?.name,
          projectColor: project?.projectColor,
          logo: project?.logo,
          languages: project?.languages,
        }}
        onSuccess={() => {
          console.log("success save project");
          refetchProject();
        }}
        variables={{
          type: "Project",
          mode: "update",
          _id: project?._id,
          contentTypeId: "",
        }}
      />
      <Flex p={15}>
        <Button
          bg="error"
          onPress={() => {
            const confirmed = confirm(
              `Projekt ${project.name} wirklich löschen?`
            );
            if (confirmed) {
              callMeteor({
                variables: {
                  name: "deleteProject",
                  options: { projectId: project._id },
                },
                refetchQueries: ["user"],
              })
                .then((result) => {
                  console.log({ result });
                  theme.alert({ type: "success", message: "Yeah" });
                  navigation.navigate("ProjectsStack");
                  refetchProject();
                })
                .catch((error) => {
                  theme.alert({ type: "error", message: error.message });
                });
            }
          }}
        >
          Projekt löschen
        </Button>
      </Flex>
    </Page>
  );
}

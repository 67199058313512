import * as React from "react";
import Svg, { Rect } from "react-native-svg";
import { ImageBackground, Image } from "react-native";
import { Blurhash } from "react-blurhash";

import {
  Animate,
  Flex,
  Button,
  Text,
  Touchable,
  Overlay,
  useLayout,
  Icon,
  useTheme,
} from "unikit";
import FileInput from "./FileInput";
import { useAppState } from "../AppContext";

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const BG = React.memo(({ w = 400, h = 180 }) => {
  const width = w;
  const height = h;
  return (
    <Flex
      flex={1}
      borderTopLeftRadius={5}
      borderTopRightRadius={5}
      overflow="hidden"
    >
      <Svg height={height} width={width} viewBox={`0 0 ${width} ${height}`}>
        {Array.from(Array(height / 10).keys()).map((y) => {
          return Array.from(Array(Math.round(width / 2 / 10)).keys()).map(
            (x) => {
              let adder = 0;
              if (y % 2) adder = 10;
              return (
                <Rect
                  key={`rect-${x}`}
                  x={x * 20 + adder}
                  y={y * 10}
                  width="10"
                  height="10"
                  fill="rgba(0,0,0,0.1)"
                />
              );
            }
          );
        })}
      </Svg>
    </Flex>
  );
});

export default ({
  title = "",
  image,
  desc,
  onPress,
  onSelect,
  onRemove,
  onCopy,
  file,
}) => {
  const theme = useTheme();
  const { callMeteor } = useAppState();
  const { onLayout, width } = useLayout();
  const [edit, setEdit] = React.useState(false);

  const rowProps = { mb: 5, row: true, alignItems: "center" };
  return (
    <>
      <Touchable
        onPress={() => {
          if (onPress) onPress();
        }}
      >
        <Animate w="100%" bg="surface" shadow={5} borderRadius={5}>
          <Flex bg="surface" position="relative" w="100%" h={180} flexCenter>
            <Flex onLayout={onLayout} absoluteFill>
              <BG />
            </Flex>
            {image ? (
              <Image
                style={{ width: "80%", height: "80%", resizeMode: "contain" }}
                source={image}
              />
            ) : null}
          </Flex>
          <Flex
            w="100%"
            px={20}
            justifyContent="space-between"
            alignItems="center"
            row
          >
            <Flex flex={1} py={18}>
              <Text w="100%" level={5} numberOfLines={1}>
                {title}
              </Text>
              {desc ? (
                <Text
                  mt={3}
                  o={0.6}
                  numberOfLines={1}
                  fontSize="label"
                  color="#999"
                >
                  {desc}
                </Text>
              ) : null}
            </Flex>
            <Flex row>
              {onSelect ? (
                <Button onPress={onSelect} size={35} mr={6} light>
                  <Icon name="plus" color="primary" size={20} />
                </Button>
              ) : (
                <Button onPress={() => setEdit(true)} size={35} mr={6} light>
                  <Icon name="edit" color="primary" size={20} />
                </Button>
              )}
            </Flex>
          </Flex>
        </Animate>
      </Touchable>
      {file ? (
        <Overlay
          visible={edit}
          p={0}
          row
          bg="surface"
          maxWidth={800}
          onClose={() => setEdit(false)}
        >
          <Flex bg="surface" position="relative" flex={1} h={500} flexCenter>
            <Flex absoluteFill>
              <BG h={500} />
            </Flex>
            {file ? (
              <Image
                style={{ width: "80%", height: "80%", resizeMode: "contain" }}
                source={{ uri: file.url }}
              />
            ) : null}
          </Flex>

          <Flex w={300} p={30}>
            <Text font="h5" pb={20}>{`${file?.name}`}</Text>
            <Flex {...rowProps}>
              <Text>{`Breite:\t\t`}</Text>
              <Text bold>{`${file?.width}`}</Text>
            </Flex>
            <Flex {...rowProps}>
              <Text>{`Höhe:\t\t`}</Text>
              <Text bold>{`${file?.height}`}</Text>
            </Flex>
            <Flex {...rowProps}>
              <Text>{`Größe:\t\t`}</Text>
              <Text bold>{`${formatBytes(file?.size)}`}</Text>
            </Flex>
            <Flex {...rowProps} mt={10}>
              {file.blurhash ? (
                <Flex borderRadius={5} mr={10} overflow="hidden">
                  <Blurhash hash={file.blurhash} width={50} height={50} />
                </Flex>
              ) : null}
              <Flex>
                <Text>{`Blurhash:`}</Text>
                {file.blurhash ? (
                  <Text>{file.blurhash}</Text>
                ) : (
                  <Button
                    onPress={async () => {
                      const result = await callMeteor({
                        variables: {
                          name: "createBlurhash",
                          options: {
                            fileId: file._id,
                            url: file.url,
                          },
                        },
                      });
                      theme.alert({
                        type: "success",
                        message: "Blurhash generiert",
                      });
                      console.log({ result });
                    }}
                  >
                    Generate
                  </Button>
                )}
              </Flex>
            </Flex>

            {file._id ? (
              <FileInput label="Bild ersetzen" fileId={file._id} />
            ) : null}
            <Flex mt={30} row>
              {onRemove ? (
                <Button onPress={onRemove} bg="error" size={35} mr={6} light>
                  <Icon name="trash" color="error" size={20} />
                </Button>
              ) : null}
              {file?.url ? (
                <Button
                  onPress={() => window.open(file?.url, "_blank")}
                  size={35}
                  mr={6}
                  light
                >
                  <Icon name="share" size={20} />
                </Button>
              ) : null}
            </Flex>
          </Flex>
        </Overlay>
      ) : null}
    </>
  );
};

import * as React from "react";
import { Blurhash } from "react-blurhash";
import { Tooltip, Touchable, Flex, Page, Text, Icon, Progress } from "unikit";
import GyroscopeAnimation from "./GyroscopeAnimation";
export const renderAction = (a, index, onPressProps = {}) => {
  if (!a) return null;
  const {
    size = 44,
    onPress,
    text,
    icon,
    color,
    bg,
    actionType,
    actions,
    toggleTooltip,
    font = "label",
    loading,
    ...rest
  } = a;
  const Comp = onPress ? Touchable : Flex;
  if (actionType === "tooltip") {
    return (
      <Tooltip
        color="rgba(255,255,255,0.8)"
        shadow={50}
        width={350}
        withOverlay={false}
        borderWidth={1}
        borderColor="primary:setAlpha:0.2"
        popover={({ toggleTooltip }) => (
          <>
            {actions?.map(({ text, ...r }, i) => (
              <>
                <Touchable
                  onPress={() => {
                    r.onPress?.(onPressProps);
                    toggleTooltip?.();
                  }}
                  alignItems="center"
                  borderBottomWidth={i === actions.length - 1 ? 0 : 1}
                  borderColor="primary:setAlpha:0.1"
                  pb={i === actions.length - 1 ? 5 : 10}
                  pt={i === 0 ? 5 : 10}
                  row
                >
                  {renderAction({ ...r, ml: 5, toggleTooltip }, i)}
                  <Text color={r.color} ml={10}>
                    {text}
                  </Text>
                </Touchable>
              </>
            ))}
          </>
        )}
        key={`action-${index || "undefined"}`}
      >
        {renderAction({ size, onPress, text, icon, color, bg })}
      </Tooltip>
    );
  }
  return (
    <Comp
      key={`action-${index || "undefined"}`}
      w={text ? "auto" : size}
      h={size}
      borderRadius={22}
      bg={bg || "primary:setAlpha:0.1"}
      center
      onPress={() => {
        onPress?.(onPressProps);
        toggleTooltip?.();
      }}
      row
      p={text ? 10 : 0}
      ml={index !== undefined && index > 0 ? 10 : 0}
      {...rest}
    >
      {loading ? (
        <Progress size={20} trackWidth={1.5} progressWidth={1.5} loading />
      ) : (
        <>
          <Icon name={icon} size={size * 0.5} color={color} />
          {text ? (
            <Text mx={10} font={font} color={color}>
              {text}
            </Text>
          ) : null}
        </>
      )}
    </Comp>
  );
};

export default ({
  header = true,
  children,
  loading = false,
  title,
  desc,
  goBack,
  leftAction,
  rightAction,
  scrollable = true,
  modal = false,
  navbarProps = {},
  pageRef,
  titleLeft,
  hash = "LlG8W[}@s*WEBYJBoMoLJ7S5axj[",
  hashProps = { opacity: 0.1, punch: 4 },
  burger = true,
  ...rest
}) => {
  return (
    <>
      {hash ? (
        <Flex
          w="100%"
          h="100%"
          overflow="hidden"
          opacity={hashProps.opacity}
          absoluteFill
        >
          <GyroscopeAnimation>
            <Blurhash
              hash={hash}
              width="100%"
              height="100%"
              resolutionX={32}
              resolutionY={32}
              punch={3}
              {...hashProps}
            />
          </GyroscopeAnimation>
        </Flex>
      ) : null}
      <Page
        bg="primary:setAlpha:0.1"
        ref={pageRef}
        titleLeft={titleLeft}
        title={title}
        subTitle={desc}
        rightAction={
          React.isValidElement(rightAction)
            ? rightAction
            : renderAction(rightAction)
        }
        leftAction={
          <Flex row>
            {goBack
              ? renderAction({
                  icon: modal ? "arrow-down" : "arrow-left",
                  onPress: goBack,
                  mr: 5,
                })
              : null}
            {React.isValidElement(leftAction)
              ? leftAction
              : Array.isArray(leftAction)
              ? leftAction.map((action, i) => renderAction(action, i))
              : renderAction(leftAction)}
          </Flex>
        }
        disableSafeArea={modal}
        scrollable={scrollable}
        borderRadius={0}
        navbarProps={{
          offset: 10,
          gradient: false,
          bg: "surface",
          animateBg: false,
          color: "#000",
          animateTitleColor: false,
          borderBottomWidth: 1,
          borderColor: "#F2EDFC",
          ...navbarProps,
        }}
        {...rest}
      >
        {loading ? (
          <Flex w="100%" flex={1} flexCenter>
            <Text>loading</Text>
          </Flex>
        ) : (
          children
        )}
      </Page>
    </>
  );
};

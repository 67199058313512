import * as React from "react";
import { Platform, StatusBar } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { ThemeProvider, Alert } from "unikit";

import { ApolloProvider } from "./api/apollo";
import { AppStateProvider, useAppState } from "./AppContext";
import AppNavigation from "./AppNavigation";

function App() {
  const { project } = useAppState();
  const linking = {
    prefixes: ["https://api.contist.de"],
    config: {
      screens: {
        Auth: "auth",
        Drawer: {
          path: "",
          screens: {
            Projects: {
              path: "/projects",
              initialRouteName: "Projects",
              screens: {
                PatientsList: "/patients",
              },
            },
          },
        },
        NoMatch: "*",
      },
    },
  };
  return (
    <ThemeProvider
      //mode="dark"
      theme={{
        colors: {
          primary: "rgb(69,90,207)",
          background: "#F0EDF8",
          text: "#000",
          shadow: "rgba(91,107,174,0.1)",
        },
        globals: {
          Button: {
            rounded: true,
          },
        },
      }}
    >
      <NavigationContainer linking={linking} fallback={null}>
        {Platform.OS === "ios" && <StatusBar />}
        <AppNavigation />
        <Alert />
      </NavigationContainer>
    </ThemeProvider>
  );
}

export default function ApolloApp(props) {
  return (
    <ApolloProvider>
      <AppStateProvider>
        <App />
      </AppStateProvider>
    </ApolloProvider>
  );
}

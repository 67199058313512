import * as React from "react";
import Svg, { G, Path } from "react-native-svg";

import { withThemeProps, transformColor } from "../../style";
import Pointer from "../Pointer";
import { useUpdateEffect } from "../../hooks";

const plotToSvg = (points: any[]) => {
  if (points.length > 0) {
    let path = `M ${points[0].x},${points[0].y}`;
    points.forEach((point) => {
      path += ` L ${point.x},${point.y}`;
    });
    return path;
  } else {
    return "";
  }
};

const Draw = React.forwardRef(
  (
    {
      theme,
      children,
      stroke = "text",
      strokeWidth = 2,
      smoothing = 0.2,
      strokeLinejoin = "round",
      onDraw,
      onClear,
      ...rest
    },
    ref
  ) => {
    const [paths, setPaths] = React.useState([]);
    const [pointer, setPointer] = React.useState(null);
    const [points, setPoints] = React.useState([]);

    React.useEffect(() => {
      if (pointer === false) {
        setPaths((p) => [...p, plotToSvg(points)]);
        setPoints([]);
      }
    }, [pointer]);

    useUpdateEffect(() => {
      if (onDraw) onDraw({ paths });
    }, [paths]);

    const pathProps = {
      stroke: transformColor({ value: stroke, theme, themeKey: "colors" }),
      strokeWidth,
      strokeLinejoin,
      fill: "none",
    };

    React.useImperativeHandle(ref, () => ({
      clear: () => {
        setPaths([]);
      },
    }));

    return (
      <>
        <Pointer
          mouse={false}
          w="100%"
          h={300}
          onMove={({ x, y, pointer }) => {
            if (pointer) setPoints((p) => [...p, { x, y }]);
            setPointer(pointer);
          }}
          onLeave={() => {
            setPointer(false);
          }}
          relative
          webStyle={{ cursor: "crosshair" }}
          {...rest}
        >
          {({ width, height }) => {
            return (
              <Svg width={width} height={height}>
                <G>
                  {paths.map((path, i) => {
                    return <Path d={path} key={`path-${i}`} {...pathProps} />;
                  })}
                  <Path d={plotToSvg(points)} {...pathProps} />
                </G>
              </Svg>
            );
          }}
        </Pointer>
      </>
    );
  }
);

export default withThemeProps(Draw, "Draw");

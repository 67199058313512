import * as React from "react";
import AsyncStorage from "@react-native-community/async-storage";
import {
  Progress,
  Input,
  Button,
  Text,
  Form,
  useTheme,
  Flex,
  CodeField,
  useInterval,
} from "unikit";
import { useApolloClient } from "@apollo/client";

import { useAppState } from "../AppContext";
import { AppIcon } from "../AppNavigation";
import { Page } from "components";
import pkg from "../package.json";

const images = [
  {
    source: require("../assets/images/hero1.jpg"),
    hash: "L88fEl9:1C=OI+xeW9NY1Wwd}KAT",
  },
  {
    source: require("../assets/images/hero2.jpg"),
    hash: "L12Z0yxumimkpJt8kDVCL#i_t:tS",
  },
  {
    source: require("../assets/images/hero3.jpg"),
    hash: "LlG8W[}@s*WEBYJBoMoLJ7S5axj[",
  },
];

const icons = ["zap", "layers", "edit", "heart", "send", "tool"];

const imageIndex = Math.floor(Math.random() * images.length) + 1 - 1;

export default function Screen() {
  const formRef = React.useRef(null);
  const { call, callState, userLoading } = useAppState();
  const client = useApolloClient();
  const theme = useTheme();
  const [step, setStep] = React.useState("login");
  const [icon, setIcon] = React.useState(0);
  const [image, setImage] = React.useState(0);

  const buttonLabels = {
    login: "Weiter",
    code: "Warte auf Code",
  };

  const labels = {
    login: "Bitte melden Sie sich an",
    code: "Ihr Code ist auf dem Weg",
  };

  useInterval(() => {
    setIcon((icon + 1) % icons.length);
  }, 1500);

  return (
    <Page
      hash={images[imageIndex].hash}
      hashProps={{ opacity: 1, punch: 1 }}
      withNavbar={false}
      scrollable={false}
    >
      <Flex
        style={{
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.1)",
        }}
      >
        <Flex center h="100%">
          <Flex flexCenter w="100%" px={30} maxWidth={600}>
            <AppIcon
              icon={icons[icon]}
              image={{ uri: images[imageIndex].source }}
              size={100}
              mb={30}
            />
            {userLoading ? (
              <Flex center w="100%" h="100%">
                <Progress
                  progressColor="#FFF"
                  size={60}
                  trackWidth={5}
                  progressWidth={5}
                  loading
                />
              </Flex>
            ) : (
              <>
                <Text font="h3" bold color="#FFF" mb={5}>
                  contist
                </Text>
                <Text color="#FFF" mb={15}>
                  {labels[step]}
                </Text>
                <Form
                  defaultDoc={{
                    email: "",
                  }}
                  button={false}
                  ref={formRef}
                  onChange={(doc) => {
                    if (doc.code && doc.code.length === 6) {
                      formRef.current.submit();
                    }
                  }}
                  onSubmit={(doc) => {
                    const { email, code } = doc;
                    if (step === "code" && code?.length === 6) {
                      call({
                        name: "getAccess",
                        options: { email, code },
                        onSuccess: ({ result }) => {
                          console.log({ token: result.data.callMeteor });
                          AsyncStorage.setItem(
                            "token",
                            result.data.callMeteor.result
                          ).then((x) =>
                            client
                              .resetStore()
                              .then(() => {
                                console.log("logout and reset");
                              })
                              .catch((error) => {
                                console.log(error);
                              })
                          );
                        },
                      });
                    } else if (step === "login") {
                      if (!email || (email && email.length === 0)) {
                        theme.alert({
                          type: "error",
                          message: "Bitte geben Sie eine valide E-Mail an",
                        });
                      } else {
                        call({
                          name: "getAccess",
                          options: { email },
                          onSuccess: () => {
                            theme.alert({
                              type: "success",
                              message: "Ihr Login-Code ist auf dem Weg",
                            });
                            setStep("code");
                          },
                        });
                      }
                    }
                  }}
                >
                  {step === "login" || step === "reset" ? (
                    <Input
                      type="text"
                      placeholder="E-Mail"
                      label="E-Mail"
                      labelProps={{ color: "#FFF" }}
                      field="email"
                      keyboardType="email-address"
                      textContentType="emailAddress"
                      autoCapitalize="none"
                    />
                  ) : null}

                  {step === "code" ? (
                    <>
                      <CodeField field="code" autoFocus />
                    </>
                  ) : null}
                </Form>
                <Button
                  w="100%"
                  size={50}
                  mt={20}
                  onPress={() => {
                    if (formRef.current) formRef.current.submit();
                  }}
                  loading={callState.loading}
                >
                  {buttonLabels[step]}
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <Flex flexCenter r={0} b={10} l={0} h={50} px={15} absolute>
          <Text color="#FFF" font="label">{`Version: ${pkg.version}`}</Text>
        </Flex>
      </Flex>
      {/* <Animate style={{ width: "90%", maxWidth: 400 }}>
        <Flex
          w="100%"
          p={25}
          bg="surface"
          borderRadius={15}
          alignItems="center"
          shadow={5}
        >
          <Avatar
            char="C"
            bg="primary"
            size={60}
            roundness={20}
            source={{
              uri: images[imageIndex],
            }}
          />
          <Text
            font="h4"
            style={{ textAlign: "center" }}
            color="primary"
            mt={20}
            animate
          >
            Bitte melden Sie sich an
          </Text>
          <Input
            type="text"
            label="E-Mail"
            value={email}
            onChange={(mail) => setMail(mail)}
            placeholder="Your E-Mail"
            style={{
              width: "100%",
              marginTop: 10,

              borderRadius: 0,
            }}
          />
          <Input
            type="text"
            label="Passwort"
            value={password}
            onChange={(password) => setPassword(password)}
            placeholder="Your Password"
            style={{
              width: "100%",
              marginTop: 10,

              borderRadius: 0,
            }}
            secureTextEntry
          />
          <Button
            mt={30}
            w="100%"
            rounded
            onPress={() => {
              if (!validateEmail(email)) {
                theme.alert({ type: "error", message: "wrong" });
                return;
              }

              getAccess({
                variables: { email, password },
              })
                .then((result) => {
                  console.log({ result });
                  AsyncStorage.setItem("token", result.data.getAccess).then(
                    (x) =>
                      client
                        .resetStore()
                        .then(() => {
                          console.log("logout and reset");
                        })
                        .catch((error) => {
                          console.log(error);
                        })
                  );

                  theme.alert({
                    type: "success",
                    message: "Login erfolgreich 😊",
                  });
                })
                .catch((error) => {
                  theme.alert({
                    type: "error",
                    message: "Ihre E-Mail oder Passwort sind falsch",
                  });
                });
            }}
          >
            Login
          </Button>
          <Button
            mt={10}
            w="100%"
            rounded
            clean
            loading={callMeteorState.loading}
            onPress={() => {
              if (!validateEmail(email)) {
                theme.alert({ type: "error", message: "E-Mail prüfen" });
                return;
              }

              callMeteor({
                variables: {
                  name: "resetPw",
                  options: {
                    email,
                  },
                },
              })
                .then((result) => {
                  theme.alert({
                    type: "success",
                    message: "E-Mail mit neuem Passwort gesendet",
                  });
                })
                .catch((error) => {
                  theme.alert({ type: "error", message: error.message });
                });
            }}
          >
            Passwort zurücksetzen
          </Button>
        </Flex>
      </Animate> */}
    </Page>
  );
}

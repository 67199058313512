import * as React from "react";
import { View, Image } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";
import { CommonActions, DrawerActions } from "@react-navigation/native";
import { Blurhash } from "react-blurhash";
import { useTheme, isWeb, Text, Flex, Icon, Touchable, Avatar } from "unikit";

import {
  Auth,
  Projects,
  Entries,
  Entry,
  Media,
  User,
  Types,
  Settings,
} from "./screens";
//import CustomDawer from "./components/CustomDawer";
import { useAppState } from "./AppContext";
import { renderAction } from "components";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const screenOptions = {
  headerShown: false,
  safeAreaInset: "never",
  animationEnabled: true,
  presentation: "card",
};

const modalOptions = {
  gestureEnabled: true,
  cardOverlayEnabled: true,
  animationEnabled: true,
  cardStyle: {
    backgroundColor: "transparent",
    paddingTop: isWeb ? 100 : 0,
    paddingBottom: isWeb ? 50 : 0,
    borderRadius: isWeb ? 20 : 0,
    maxWidth: 600,
    width: "100%",
    alignSelf: "center",
  },
};

export const AppIcon = ({
  size = 60,
  bg = "primary",
  logo,
  image,
  icon = "zap",
  char,
  ...rest
}) => {
  return (
    <Avatar
      bg={bg}
      roundness={size * 0.2}
      size={size}
      source={image}
      char={char}
      {...rest}
    >
      {logo ? (
        <Image
          source={{ uri: logo }}
          style={{
            width: size * 0.66,
            height: size * 0.66,
            resizeMode: "contain",
          }}
        />
      ) : char ? null : (
        <Icon name={icon} size={size * 0.6} animate={true} />
      )}
    </Avatar>
  );
};

const CustomDawer = ({
  state,
  navigation,
  descriptors,
  logo,
  footerComponent = null,
}) => {
  return (
    <Flex w="100%" flex={1} bg="#000" justifyContent="space-between">
      <Flex opacity={0.9} overflow="hidden" absoluteFill>
        <Blurhash
          hash={"LlG8W[}@s*WEBYJBoMoLJ7S5axj["}
          width="150%"
          height="100%"
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </Flex>
      <Flex>
        <AppIcon
          logo={logo}
          image={require("./assets/images/hero3.jpg")}
          m={10}
          size={50}
        />
        {state.routes.map((route, i) => {
          const focused = i === state.index;
          const { title, label, hidden, icon, iconSize } =
            descriptors[route.key].options;
          if (hidden) return null;
          return (
            <>
              {/* {label ? (
                <Text
                  color="#FFF"
                  numberOfLines={1}
                  font="label"
                  px={20}
                  pb={10}
                  pt={20}
                >
                  {label}
                </Text>
              ) : null} */}
              <Touchable
                bg={focused ? "rgba(0,0,0,0.2)" : undefined}
                key={route.key}
                onPress={() => {
                  navigation.dispatch({
                    ...(focused
                      ? DrawerActions.closeDrawer()
                      : CommonActions.navigate({
                          name: route.name,
                          merge: true,
                        })),
                    target: state.key,
                  });
                }}
                row
                alignItems="center"
                px={20}
                py={15}
              >
                <Icon
                  name={icon}
                  size={iconSize || undefined}
                  mr={10}
                  animate={focused}
                  animateFillPath="surface:lighten:10:setAlpha:0.5"
                  duration={400}
                  strokeWidth={focused ? 1.5 : 1}
                  color={
                    focused
                      ? "surface:lighten:30"
                      : "surface:lighten:20:setAlpha:0.5"
                  }
                />
                {/* <Text
                  numberOfLines={1}
                  style={{
                    color: "#FFF",
                    fontWeight: "500",
                  }}
                >
                  {title}
                </Text> */}
              </Touchable>
            </>
          );
        })}
      </Flex>
      {footerComponent}
    </Flex>
  );
};

const screens = [
  <Stack.Screen
    name="Media"
    component={Media}
    options={{
      ...screenOptions,
    }}
  />,
];

const Navigation = () => {
  const theme = useTheme();
  const { user, userLoading, projectId, project, logout, inRole } =
    useAppState();
  console.log({ project });
  if (!user && userLoading) {
    return (
      <Stack.Navigator screenOptions={{ animationEnabled: false }}>
        <Stack.Screen
          name="Home"
          options={{
            ...screenOptions,
            headerShown: false,
          }}
        >
          {() => (
            <View>
              <Text>loading</Text>
            </View>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    );
  }

  if (!user)
    return (
      <Stack.Navigator screenOptions={{ animationEnabled: false }}>
        <Stack.Screen
          name="Auth"
          component={Auth}
          options={{ ...screenOptions, path: "auth", headerShown: false }}
        />
      </Stack.Navigator>
    );

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          animationEnabled: false,
          cardOverlayEnabled: true,
        }}
        mode="modal"
      >
        <Stack.Screen
          name="Main"
          options={{
            headerShown: false,
            ...screenOptions,
          }}
        >
          {() => (
            <Drawer.Navigator
              initialRouteName="Projects"
              drawerType="permanent"
              drawerContent={(props) => (
                <CustomDawer
                  {...props}
                  logo={project?.logoFile?.thumbnailUrl}
                  footerComponent={
                    <Flex w="100%" p={12}>
                      {renderAction({
                        icon: "log-out",
                        bg: "rgba(0,0,0,0.2)",
                        color: "#FFF",
                        onPress: () => logout(),
                      })}
                    </Flex>
                  }
                />
              )}
              screenOptions={{
                headerShown: false,
                drawerStyle: {
                  backgroundColor: "transparent",
                  width: 70,
                },
                drawerLabelStyle: { color: "#FFF" },
                overlayColor: "transparent",
                drawerType: "permanent",
              }}
            >
              <Drawer.Screen
                name="ProjectsStack"
                options={{
                  title: "Meine Projekte",
                  icon: "grid",
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="Projects">
                    <Stack.Screen
                      name="Projects"
                      component={Projects}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    <Stack.Screen
                      name="Entry"
                      component={Entry}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
              <Drawer.Screen
                name="EntriesStack"
                options={{
                  title: "Content",
                  icon: "layers",
                  label: project?.name || "Projekt",
                  hidden: !projectId,
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="Entries">
                    <Stack.Screen
                      name="Entries"
                      component={Entries}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    <Stack.Screen
                      name="Entry"
                      component={Entry}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
              <Drawer.Screen
                name="MediaStack"
                options={{
                  title: "Media",
                  icon: "image",
                  hidden: !projectId,
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="Media">
                    <Stack.Screen
                      name="MediaMain"
                      component={Media}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
              <Drawer.Screen
                name="TypesStack"
                options={{
                  title: "Types",
                  icon: "database",
                  hidden:
                    !projectId ||
                    (projectId &&
                      !inRole({ roles: ["Admin"], key: projectId })),
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="Types">
                    <Stack.Screen
                      name="Types"
                      component={Types}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    <Stack.Screen
                      name="Entry"
                      component={Entry}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
              <Drawer.Screen
                name="UserStack"
                options={{
                  title: "User",
                  icon: "user",
                  hidden:
                    !projectId ||
                    (projectId &&
                      !inRole({ roles: ["Admin", "Author"], key: projectId })),
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="User">
                    <Stack.Screen
                      name="User"
                      component={User}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    <Stack.Screen
                      name="Entry"
                      component={Entry}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
              <Drawer.Screen
                name="SettingsStack"
                options={{
                  title: "Settings",
                  icon: "settings",
                  hidden:
                    !projectId ||
                    (projectId &&
                      !inRole({ roles: ["Admin", "Author"], key: projectId })),
                }}
              >
                {() => (
                  <Stack.Navigator initialRouteName="Settings">
                    <Stack.Screen
                      name="Settings"
                      component={Settings}
                      options={{
                        ...screenOptions,
                      }}
                    />
                    {screens}
                  </Stack.Navigator>
                )}
              </Drawer.Screen>
            </Drawer.Navigator>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </>
  );
};

export default Navigation;

import SimpleSchema from "simpl-schema";

const Options = [
  "group",
  "listed",
  "component",
  "slugify",
  "options",
  "relation",
  "getValue",
  "formType",
  "description",
  "schema",
  "minmax"
];

SimpleSchema.extendOptions(Options);

export default Options;

import * as React from "react";
import { Animate, Flex, Button, Text, Touchable } from "unikit";
import { Icon } from "../components";

export default ({ title = "", desc, onPress, onCopy, onRemove, ...rest }) => {
  return (
    <Touchable
      onPress={() => {
        if (onPress) onPress();
      }}
      {...rest}
    >
      <Animate
        as={Flex}
        w="100%"
        px={20}
        py={18}
        bg="surface"
        alignItems="center"
        justifyContent="space-between"
        row
        shadow={5}
        borderRadius={5}
      >
        <Flex f={1} style={{ maxWidth: "calc(100% - 200px)" }}>
          <Text level={5} numberOfLines={1}>
            {title}
          </Text>
          {desc ? (
            <Text
              mt={3}
              o={0.6}
              numberOfLines={1}
              fontSize="label"
              color="#999"
            >
              {desc}
            </Text>
          ) : null}
        </Flex>
        <Flex row>
          {onCopy ? (
            <Button onPress={onCopy} size={35} mr={6} light>
              <Icon name="copy" color="primary" size={20} />
            </Button>
          ) : null}
          {onRemove ? (
            <Button onPress={onRemove} size={35} bg="error" light>
              <Icon name="trash" color="error" size={20} />
            </Button>
          ) : null}
        </Flex>
      </Animate>
    </Touchable>
  );
};

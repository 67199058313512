import gql from "graphql-tag";
import { useApolloClient, useMutation } from "@apollo/client";

import { ContentEntrySchema } from "../contist-server/lib/schemas";
console.log({ ContentEntrySchema });

export { useApolloClient, useMutation, gql };

export const HANDLE_DOC = gql`
  mutation handleDoc(
    $type: String!
    $_id: ID
    $content: Object!
    $mode: String!
  ) {
    handleDoc(type: $type, _id: $_id, content: $content, mode: $mode)
  }
`;

export const CALL_METEOR = gql`
  mutation callMeteor($name: String!, $options: Object) {
    callMeteor(name: $name, options: $options)
  }
`;

import * as React from "react";
import { Tabs, Button, useTheme, Flex } from "unikit";

import { NoContent, List, Page, Form, renderAction } from "components";

import { useAppState } from "../AppContext";

export default function Screen({ navigation, route }) {
  const theme = useTheme();
  const { set, project, projectLoading, refetchProject, call } = useAppState();
  const [type, setType] = React.useState(null);
  const formRef = React.useRef(null);

  const { contentTypes = [] } = project || {};

  React.useEffect(() => {
    if (route.params?.projectId) {
      set({ projectId: route.params?.projectId });
    }
  }, [route.params?.projectId]);

  React.useEffect(() => {
    if (contentTypes.length > 0 && contentTypes[0]._id !== type) {
      setType(contentTypes[0]._id);
    }
  }, [contentTypes]);

  if (projectLoading || !project) return null;

  const selectedType = contentTypes.find((c) => c._id === type);
  const isSingle = selectedType && selectedType.singleDoc;

  console.log({ selectedType });

  return (
    <Page
      title="Content"
      goBack={route?.params?.onSelect ? navigation.goBack : undefined}
      rightAction={renderAction({
        icon: isSingle ? "check" : "plus",
        bg: "primary",
        color: "#FFF",
        onPress: () => {
          if (isSingle) {
            const doc = formRef.current?.getDoc();
            call({
              name: "updateContentTypeContent",
              options: {
                content: doc,
                projectId: project._id,
                contentTypeId: selectedType._id,
              },
              onSuccess: () => {
                theme.alert({
                  type: "success",
                  message: "Erfolgreich gespeichert",
                });
                refetchProject();
              },
            });
          } else {
            navigation.navigate("Entry", {
              title: `${selectedType.name} erstellen`,
              mode: "insert",
              typeId: selectedType._id,
              projectId: project._id,
              type: "ContentEntry",
              onSuccess: () => {
                navigation.goBack();
              },
              refetchQueries: ["contententrys"],
            });
          }
        },
      })}
      navbarProps={{
        footerComponent: (
          <Flex w="100%" borderTopWidth={2} borderColor="primary:setAlpha:0.1">
            {contentTypes.length > 0 && !route?.params?.typeId ? (
              <Tabs
                indicatorSize={2}
                mt={-2}
                bg="transparent"
                activeColor="primary"
                color="text"
                options={contentTypes.map((item) => ({
                  value: item._id,
                  label: `${item.name}${
                    item.singleDoc ? `` : `(${item.count})`
                  }`,
                }))}
                onChange={(value) => setType(value)}
              />
            ) : null}
          </Flex>
        ),
      }}
    >
      {selectedType ? (
        selectedType.singleDoc ? (
          <Form
            ref={formRef}
            defaultDoc={selectedType.content || {}}
            schema={selectedType.schema}
          />
        ) : (
          <List
            relation={route?.params?.typeId || type}
            count={selectedType ? selectedType.count : 0}
            style={{ padding: 10 }}
            push={navigation.push}
            projectId={project._id}
            onSelect={route?.params?.onSelect}
            search
          />
        )
      ) : (
        <NoContent
          title="No ContentTypes created"
          desc="Start by creating a ContentType that fit your needs."
          action="Create new Type"
          onAction={() => {
            navigate("Types", {
              projectId: project._id,
            });
          }}
        />
      )}

      {/* <ListViewQuery
        query={GET_ENTRIES}
        filter={{
          contentTypeId: getParam("typeId")
        }}
        sort={{ [`content.${titleField}`]: 1 }}
        gap={10}
        name="contententrys"
        infinite={false}
        renderItem={({ item, index, limit }) => {
          return (
            <TouchableOpacity
              onPress={() => {
                push("Entry", {
                  title: item.content[titleField],
                  typeId: getParam("typeId"),
                  defaultDoc: item.content
                });
              }}
            >
              <Animate
                as={Flex}
                delay={(index - limit) * 150}
                w="100%"
                px={20}
                py={18}
                bg="surface"
                align="center"
                content="space-between"
                row
                shadow={35}
                br={5}
                mb={10}
              >
                <Flex f={1} style={{ maxWidth: "calc(100% - 200px)" }}>
                  <Headline level={4} numberOfLines={1}>
                    {item.content[titleField]}
                  </Headline>
                  <Text
                    mt={3}
                    o={0.6}
                    numberOfLines={1}
                    fontSize="label"
                    color="#999"
                  >
                    {item.content[titleField]}
                  </Text>
                </Flex>
                <Flex row>
                  <Button size={35} mr={6} light>
                    <Icon name="copy" color="primary" size={20} />
                  </Button>
                  <Button size={35} bg="error" light>
                    <Icon name="trash" color="error" size={20} />
                  </Button>
                </Flex>
              </Animate>
            </TouchableOpacity>
          );
        }}
      /> */}
    </Page>
  );
}

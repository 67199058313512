import React from "react";
import { TouchableOpacity } from "react-native";
import { Feather } from "@expo/vector-icons";

import { useTheme } from "unikit";

export default ({ name, focused, size, color, onPress, style }) => {
  const theme = useTheme();
  const icon = (
    <Feather
      name={name}
      size={size || 25}
      style={onPress ? undefined : style}
      color={
        color
          ? theme.colors[color] || color
          : focused
          ? theme.colors.primary
          : theme.colors.grey || "#CCC"
      }
    />
  );
  if (onPress) {
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          {
            width: 40,
            height: 40,
            alignItems: "center",
            justifyContent: "center"
          },
          style
        ]}
      >
        {icon}
      </TouchableOpacity>
    );
  }
  return icon;
};

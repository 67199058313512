import React, { Fragment, useState } from "react";
import { Image } from "react-native";
import { Flex, Text, Button } from "unikit";

export default ({ title = "", desc, image, action = "GoTo", onAction }) => {
  return (
    <Flex h={220} flexCenter>
      {image ? (
        <Image
          source={image}
          style={{
            width: 250,
            height: 250,
            marginBottom: 20,
            resizeMode: "contain",
          }}
        />
      ) : null}
      <Text style={{ textAlign: "center" }} level={5}>
        {title}
      </Text>
      {desc ? (
        <Text mt={10} fs="label">
          {desc}
        </Text>
      ) : null}
      {onAction ? (
        <Button onPress={onAction} mt={10} rounded light>
          {action}
        </Button>
      ) : null}
    </Flex>
  );
};

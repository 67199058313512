import * as React from "react";
import { ScrollView } from "react-native";

import ReactQuill, { Quill } from "react-quill";
import { useTheme, Flex, Text } from "unikit";
import "react-quill/dist/quill.snow.css";

import { useNavigation } from "../AppContext";

const CustomHeart = () => <span>♥</span>;

function insertHeart() {
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, "♥");
  this.quill.setSelection(cursorPosition + 1);
}

/*
 * Custom toolbar component including the custom heart button and dropdowns
 */
const CustomToolbar = () => (
  <div id="toolbar">
    <select
      className="ql-header"
      defaultValue={""}
      onChange={(e) => e.persist()}
    >
      {["1", "2", "3", "4", "5", "6", "false"].map((value) => (
        <option key={value} value={value} />
      ))}
    </select>
    <button class="ql-bold"></button>
    <button class="ql-italic"></button>

    <select className="ql-align" />
    <select className="ql-color" />
    <button className="ql-link" />
    <button className="ql-video" />
    <button className="ql-clean" />
    <button className="ql-image" />
    <button className="ql-raw">Raw</button>
  </div>
);

export default function Screen({ value, onChange }) {
  let quillRef = React.useRef();
  const [raw, setRaw] = React.useState(false);
  const navigation = useNavigation();

  const insertImage = () => {
    navigation.push("Media", {
      onSelect: ({ item }) => {
        console.log({ item, quillRef: quillRef.current.getEditor() });
        navigation.goBack();
        quillRef.current
          .getEditor()
          .insertEmbed(
            quillRef.current.getEditor().selection.savedRange.index,
            "image",
            item.url,
            "user"
          );
      },
    });
  };

  const toggleRaw = () => {
    setRaw((r) => !r);
  };

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: "#toolbar",
        handlers: {
          image: insertImage,
          raw: toggleRaw,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
  ];
  return (
    <>
      <Flex w="100%" height="auto" bg="#FFF">
        <div className="text-editor">
          <CustomToolbar />

          <ReactQuill
            ref={quillRef}
            defaultValue={value}
            onChange={(html) => onChange(html)}
            modules={modules}
            formats={formats}
          />
        </div>
      </Flex>

      {/* <Flex w="100%" height={300} bg="#FFF">
        <ScrollView style={{ flex: 1 }}>
          <Text>{value}</Text>
        </ScrollView>
      </Flex> */}
    </>
  );
}

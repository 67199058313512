import * as React from "react";
import { Button, Flex, Text, Collapsible } from "unikit";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigation } from "@react-navigation/native";

import ListItem from "./ListItem";

var schema = {
  label: {
    label: "Name",
    component: "Text",
  },
  key: {
    label: "Key",
    component: "Text",
    readonly: true,
    slugify: "label",
  },
  type: {
    label: "Type",
    component: "Tabs",
    defaultValue: "String",
    options: [
      {
        value: "String",
        label: "String",
      },
      {
        value: "Boolean",
        label: "Boolean",
      },
      {
        value: "Number",
        label: "Number",
      },
      {
        value: "Array",
        label: "Array",
      },
      {
        value: "Object",
        label: "Object",
      },
    ],
  },
  component: {
    label: "Component",
    component: "Components",
    defaultValue: "Text",
    getValue: "type",
  },
  relation: {
    label: "Relation",
    component: "Select",
    optional: true,
    listed: ({ doc }) => {
      return doc["component"] === "Relation";
    },
    options: ({ context }) => {
      var array = [];
      context?.contentTypes.map((contentType) => {
        array.push({
          value: contentType._id,
          label: contentType.name,
        });
      });
      return array;
    },
  },
  relationType: {
    label: "Relation Key",
    component: "Select",
    optional: true,
    listed: ({ doc }) => {
      return doc["component"] === "Relation";
    },
    options: ({ doc, context }) => {
      var array = [];
      var contentType = context?.contentTypes.find(
        (c) => c._id === doc.relation
      );
      if (contentType) {
        var objectKeys = Object.keys(contentType.schema);
        objectKeys.map((objectKey) => {
          array.push({
            value: objectKey,
            label: objectKey,
          });
        });
      }
      return array;
    },
  },
  slugify: {
    label: "Slugify",
    component: "Select",
    optional: true,
    listed: ({ doc }) => {
      return doc["component"] === "Slug";
    },
    options: ({ doc, options }) => {
      var array = [];
      //   var keys = Object.keys(parentState["schema"]);
      //   console.log("parentState[]", parentState["schema"], keys);
      //   keys.map((key) => {
      //     if (parentState["schema"][key].component === "Text") {
      //       array.push({
      //         value: key,
      //         label: key,
      //       });
      //     }
      //   });
      return array;
    },
  },
  options: {
    label: "Select Options",
    component: "Tags",
    optional: true,
    listed: ({ doc }) => {
      return doc["component"] === "Select" || doc["component"] === "Checkboxes";
    },
  },
  schema: {
    label: "List Schema",
    component: "Schema",
    optional: true,
    listed: ({ doc }) => {
      return doc["component"] === "List";
    },
  },
  optional: {
    label: "Optional",
    component: "Toggle",
    defaultValue: false,
  },
  preview: {
    label: "Als Titel Feld nutzen",
    component: "Toggle",
    defaultValue: false,
    listed: ({ doc }) => {
      return doc["type"] === "String";
    },
  },
  groupBy: {
    label: "Gruppieren",
    component: "Toggle",
    defaultValue: false,
    listed: ({ doc }) => {
      return doc["type"] === "Array" && doc["component"] === "Relation";
    },
  },
  schema: {
    label: "Schema",
    component: "Schema",
    defaultValue: false,
    listed: ({ doc }) => {
      return doc["component"] === "List";
    },
  },
  previewImage: {
    label: "Als Vorschau Bild nutzen",
    component: "Toggle",
    defaultValue: false,
    listed: ({ doc }) => {
      return doc["component"] === "Media" && doc["type"] === "Object";
    },
  },
  translated: {
    label: "Übersetzbar",
    component: "Toggle",
    defaultValue: false,
    listed: ({ doc }) => {
      return doc["type"] === "String";
    },
  },
};

const grid = 0;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

export default function Screen({ value, onChange, context }) {
  const { push } = useNavigation();
  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          const newObj = {};
          const newIndex = result.destination.index;
          const oldIndex = result.source.index;
          if (oldIndex !== newIndex) {
            Object.keys(value).map((key, index) => {
              if (index === newIndex && oldIndex > newIndex) {
                newObj[result.draggableId] = value[result.draggableId];
              }
              if (index !== oldIndex) {
                newObj[key] = value[key];
              }
              if (index === newIndex && oldIndex < newIndex) {
                newObj[result.draggableId] = value[result.draggableId];
              }
            });
            console.log({ newIndex, oldIndex, newObj, result });
            onChange(newObj);
          }
        }}
      >
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div
              ref={droppableProvided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
            >
              {value
                ? Object.keys(value).map((key, index) => {
                    const item = value[key];
                    return (
                      <Draggable
                        key={item.key}
                        draggableId={item.key}
                        index={index}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                            style={getItemStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style
                            )}
                          >
                            <ListItem
                              title={item.label}
                              mb={10}
                              onPress={() => {
                                push("Entry", {
                                  title: item.label,
                                  mode: "update",
                                  defaultDoc: item,
                                  schema,
                                  context,
                                  onSubmit: ({ doc, navigation }) => {
                                    const newValue = {
                                      ...value,
                                      [doc.key]: doc,
                                    };
                                    if (doc.key !== key) {
                                      delete newValue[key];
                                    }
                                    onChange(newValue);
                                    navigation.goBack();
                                  },
                                });
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    );
                  })
                : null}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        onPress={() => {
          push("Entry", {
            title: "Schema hinzufügen",
            mode: "insert",
            schema,
            context,
            defaultDoc: {
              type: "String",
              component: "Text",
            },
            onSubmit: ({ doc, navigation }) => {
              const newValue = { ...(value || {}), [doc.key]: doc };
              onChange(newValue);
              navigation.goBack();
            },
          });
        }}
      >
        Hinzufügen
      </Button>
    </>
  );
}
